<template>
  <div class="mobileVerifePop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="close-wrapper">
        <button type="button" class="dark-close" @click="close()"></button>
      </div>
      <div class="remindinfo-block">
        <div class="title-block">
          <div class="popup-icon"></div>
          <h2 class="remindinfo-title">{{t('Mobile Verification')}}</h2>
        </div>
        <div class="remindinfo-bg">
          <div class="remindinfo-inner popup-input-content">
            <div class="form-group verification-box">
              <h4>{{t('Verification code.')}}</h4>
              <div class="input-group">
                <input type="text" v-model="obj.code" class="form-input verify_code_input" :placeholder="t('Verification code.')" @input="validateCode()">
                <div class="verify_code">
                  <div class="center-wrap input-addon button">
                    <button class="popup-btn-mobile-verification" @click="sendVerification()">
                      <span v-if="!obj.needWait">{{t('Send verification')}}</span>
                      <span v-else> {{tools.stringFormat(t('Try again in [0] seconds'),[obj.time ])}}</span>
                    </button>
                  </div>
                </div>
                <div v-if="obj.errShow" class="poperrorMsg">{{t('Please enter the verification Code')}}</div>
              </div>
            </div>
            <div class="center-wrap button-group">
              <button type="submit" class="popup-btn-common-style mobileVerification-confirm" @click="confirm()">{{t('Confirm')}}</button>
              <div class="mobileVerification-memo">{{t('If you still did not receive verification code, please contact Customer Service.')}}</div>
            </div>
          </div>
          <div v-if="false" class="overlay-mask">
            <div class="mask-icon">
              <div class="spinner">
              </div>
              <span class="contentDefault">{{t('Processing...')}}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage, ElNotification } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {}
})
let timeFuc = null;
const obj = reactive({
  code: '',
  errShow: false,
  needWait: false,
  time: 180,
  phone: userInfo.value.Phone
})


async function sendVerification() {
  if (obj.needWait) {
    ElMessage.warning(t('Please try again later'));
    return;
  }
  let params = {
    phone: obj.phone,
    area: 0,
    event: 'verifyPhone',
    language: tools.getLanguage(),
  }
  log.info('params---', params)
  let rsp = await req.post(http.smsSend, qs.stringify(params));
  if (rsp.status == 200 && rsp.data.Code == 0) {
    obj.needWait = true;
    startWaitTime();
    ElNotification.success({
      title: t('Success'),
      message: t('Successfully sent'),
    })
  } else {
    obj.needWait = true;
    startWaitTime();
  }
}

function startWaitTime() {
  timeFuc && clearTimeout(timeFuc);
  timeFuc = setInterval(() => {
    if (obj.time <= 0) {
      timeFuc && clearTimeout(timeFuc);
      obj.needWait = false;
      obj.time = 180;
      return;
    }
    obj.time = obj.time - 1;
  }, 1000);
}

function validateCode() {
  let value = obj.code;
  if (value === '') {
    obj.errShow = true;
  } else {
    obj.errShow = false;
  }
}

function confirm() {
  validateCode();
  let params = {
    code: obj.code,
    event: 'verifyPhone'
  };
  mqant.request(topic.setVerifyPhone, params, function (data, topicName, msg) {
    log.info('setVerifyPhone--', data.Data)
    if (data.Code == 0) {
      userInfo.value.Verify = 1;
      comApi.setUserInfo();
      close();
      ElMessage.success(t('Operation successful'));
    } else {
      ElMessage.error(data.ErrMsg);
    }
  });
}

function close() {
  bus.emit(bus.event.mobileVerifePop, false);
}
</script>

<style lang="scss" scoped>
.mobileVerifePop {
  position: relative;
  text-align: center;

  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    border-radius: 2px;
    position: relative;
    margin: 0 auto 50px;
    width: 700px;
    max-width: 100%;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #606266;
      font-size: 14px;
      overflow: visible;
      padding: 0;

      .close-wrapper {
        .dark-close {
          position: absolute;
          top: 80px;
          right: 40px;
          z-index: 10;
          padding: 0;
          border: 0;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;
          width: 30px;
          height: 30px;
          -webkit-appearance: none;
          background: url("@/assets/pc/common/dark-close.png");
        }
      }

      .remindinfo-block {
        position: relative;
        padding-top: 45px;

        .title-block {
          background: #fff;
          padding: 20px;
          text-align: center;
          position: relative;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;

          .popup-icon {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: -45px;
            background: #ffbf00;
          }

          .popup-icon:before {
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 50%;
            filter: grayscale(100%) brightness(250%);
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: url("@/assets/pc/pop/systeminfo-icon.png");
          }

          .remindinfo-title {
            color: #ffbf00;
            word-break: break-all;
            margin: 50px 0 0;
            word-wrap: break-word;
            font-size: 30px;
            font-weight: 500;
          }
        }

        .remindinfo-bg {
          background-color: #fff;
          border-bottom-right-radius: 20px;
          border-bottom-left-radius: 20px;
          margin-top: -1px;
          padding-top: 1px;
          position: relative;

          .remindinfo-inner {
            *zoom: 1;
            text-align: center;
            padding: 20px;

            .form-group {
              margin-bottom: 30px !important;

              h4 {
                margin: 0;
                color: #6c5f4e;
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
                text-align: left;
              }

              .input-group {
                *zoom: 1;
                position: relative;
                display: table;
                width: 100%;
                border-collapse: separate;

                .form-input {
                  display: block;
                  width: 100%;
                  height: 50px;
                  padding: 10px;
                  font-size: 16px;
                  line-height: 30px;
                  color: #616161;
                  background: #fff;
                  border: none;
                  border-bottom: 1px solid #ddd;
                  -webkit-box-shadow: none;
                  box-shadow: none;
                  -webkit-transition: none;
                  transition: none;
                  border-radius: 0;
                }

                .form-input:focus {
                  color: #000;
                  border-color: #f7f7f7;
                  background-color: #f7f7f7;
                  outline: 0;
                  -webkit-box-shadow: none;
                  box-shadow: none;
                }

                .verify_code_input {
                  width: 65% !important;
                }

                .verify_code {
                  position: absolute;
                  min-width: 36%;
                  max-width: 63%;
                  min-height: 50px;
                  display: block;
                  right: 0;
                  top: 0;
                  border: 0;

                  .input-addon {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px !important;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: middle;
                    border: 1px solid #ddd;

                    .popup-btn-mobile-verification {
                      background-image: -webkit-gradient(
                        linear,
                        left top,
                        left bottom,
                        from(#ea9f13),
                        to(#bb7f0f)
                      );
                      background-image: linear-gradient(
                        180deg,
                        #ea9f13 0,
                        #bb7f0f
                      );
                      background-repeat: repeat-x;
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEA9F13", endColorstr="#FFBB7F0F", GradientType=0);
                      -webkit-box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
                      box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
                      -webkit-transition: all 0.5s ease;
                      transition: all 0.5s ease;
                      cursor: pointer;
                      display: inline-block;
                      width: auto;
                      height: auto;
                      padding: 10px 40px;
                      font-size: 18px;
                      color: #fff;
                      line-height: 30px;
                      letter-spacing: 1px;
                      text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
                      border-radius: 50px;
                      border: 2px solid #bb7f0f;
                      width: 100%;
                      margin-bottom: 10px;

                      font-size: 16px;
                      min-height: 50px;
                      line-height: 20px;
                      border-radius: unset;
                      padding: 3px 10px;
                      letter-spacing: unset;
                      white-space: break-spaces;
                      width: 100%;
                      margin: 0;
                      min-width: auto;
                    }

                    .popup-btn-mobile-verification:hover {
                      background-image: -webkit-gradient(
                        linear,
                        left top,
                        left bottom,
                        from(#bb7f0f),
                        to(#ea9f13)
                      );
                      background-image: linear-gradient(
                        180deg,
                        #bb7f0f 0,
                        #ea9f13
                      );
                      background-repeat: repeat-x;
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFBB7F0F", endColorstr="#FFEA9F13", GradientType=0);
                    }
                  }
                }

                .poperrorMsg {
                  position: absolute;
                  z-index: 20;
                  bottom: 50px;
                  right: 0;
                  width: auto;
                  background: #c00;
                  color: #fff;
                  line-height: 20px;
                  padding: 0;
                  border-radius: 3px;
                  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
                  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
                }

                .poperrorMsg:before {
                  content: "";
                  font-family: glyphicon;
                  font-weight: 600;
                  margin-right: 5px;
                  top: 1px;
                  position: relative;
                }

                .poperrorMsg:after {
                  content: "";
                  width: 0;
                  height: 0;
                  position: absolute;
                  margin: auto;
                  right: 10px;
                  bottom: -6px;
                  display: block;
                  border-color: #c00 transparent transparent;
                  border-style: solid;
                  border-width: 7px 7px 0;
                }
              }
            }

            .center-wrap {
              margin-bottom: 10px !important;
              text-align: center;

              .popup-btn-common-style {
                margin-bottom: 10px;
                margin: 0 5px;
                background-image: linear-gradient(180deg, #ffbf00 0, #fe725d);
                background-repeat: repeat-x;
                -webkit-box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
                box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
                -webkit-transition: all 0.5s ease;
                transition: all 0.5s ease;
                cursor: pointer;
                display: inline-block;
                width: auto;
                height: auto;
                padding: 10px 40px;
                font-size: 18px;
                color: #fff;
                line-height: 30px;
                letter-spacing: 1px;
                text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
                border-radius: 50px;
                border: 2px solid #fe725d;
                width: 100%;
                width: auto !important;
              }

              .popup-btn-common-style:hover {
                background-image: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  from(#fe725d),
                  to(#ffbf00)
                );
                background-image: linear-gradient(180deg, #ffbf00 0, #ffbf00);
                background-repeat: repeat-x;
              }

              .mobileVerification-memo {
                padding-top: 30px;
                text-align: center;
              }
            }
          }

          .overlay-mask {
            width: 100%;
            height: 100%;
            display: block;
            z-index: 99;
            background-color: rgba(0, 0, 0, 0.8);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .mask-icon {
              height: 100px;
              margin: auto;
              text-align: center;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            .spinner {
              border: 0.3rem solid #1e1f21;
              border-left-color: #2ecc71;
              border-radius: 50%;
              width: 2.5rem;
              margin: 10px auto;
              height: 2.5rem;
              -webkit-animation: rodar 0.5s linear infinite;
              animation: rodar 0.5s linear infinite;
            }

            .contentDefault {
              text-align: center;
              color: #fff;
              white-space: 2px;
            }

            .contentDefault:before {
              content: "";
              color: #fff;
              display: block;
              text-align: center;
              -webkit-animation: pulse 2s infinite;
              animation: pulse 2s infinite;
            }

            @keyframes pulse {
              0% {
                content: "Loading.";
                -webkit-transform: scale(1);
                transform: scale(1);
              }

              50% {
                content: "Loading..";
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              100% {
                content: "Loading...";
                -webkit-transform: scale(1);
                transform: scale(1);
              }
            }

            @keyframes rodar {
              100% {
                -webkit-transform: rotate(1turn);
                transform: rotate(1turn);
              }
            }
          }
        }
      }
    }
  }
}
</style>