<template>
  <div class="root">
    <div class="x-cover" :style="{'background-image':`url('${comApi.getPromoImg('Activity')}')`}">
    </div>

    <div class="promo-menu">
      <div class="web_view menu-fluid">
        <h2 class="title-left">{{ t(navObj.list[navObj.active].name) }}</h2>
        <div class="title-right">
          <a v-for="(item, index) in navObj.list" class="item" :class="navObj.active == index ? 'active' : ''" @click="itemClick(item, index)">
            <span>{{ tools.getCurLangValue(item.name) }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <ul class="promo-list">
        <li v-for="(item, index) in navObj.list[navObj.active].list">
          <div class="promo-list-block">
            <ul class="promo-grid">
              <li @click="toInfo(item)">
                <div class="promo-banner">
                  <a>
                    <img :src="getBannerImg(item)" lazy="loaded">
                  </a>
                </div>
              </li>
              <li>
                <div class="promo-content">
                  <h2 class="promo-title">
                    <a>{{ tools.getCurLangValue(item.title) }}</a>
                  </h2>
                  <div class="promo-detail">
                    <p><b>{{t('Promotion Tag')}}</b>
                      <span>{{ getPromotionTag(item) }}</span></p>
                    <p v-if="item.factoryList.length > 0">
                      <b>{{t('Promotion Platform')}}</b>
                      <span>{{getPromotionFactory(item) }}</span></p>
                    <p><b>{{t('Promotional Period')}}</b>
                      <span>{{ item.openTime[0] }} ~ {{ item.openTime[1]}}</span></p>
                  </div>
                </div>
              </li>
            </ul>
            <div class="promo-btn" @click="toInfo(item)">
              <a>{{t('MORE')}}</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
import { isArray } from "xe-utils";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const curType = 'Activity';
const curTag = "activityArtical";
const curTypeObj = tools.deepClone(lobbyInfo.value.MainPageConf.find(info => info._id == curType)).Labels;
const curTypeLabelList = getLabelList(curTypeObj);

const navObj = reactive({
  active: 0,
  list: [
    { name: t('All Promotions'), list: getAllList() },
  ],
})
//设置分类的数据
for (let key in curTypeLabelList) {
  navObj.list.push(
    { name: tools.getCurLangValue(curTypeLabelList[key].Text), list: getListByFlag(curTypeLabelList[key].Navigate) }
  )
}

log.info('allList----', navObj.list);


watch(() => {
  curRouter.path, toPromotionPopById();
})

function toPromotionPopById(id) {
  if (curRouter.path == '/promotion') {
    if (curRouter.params && curRouter.params.id) {
      if (navObj.list[0].list && navObj.list[0].list.length > 0) {
        for (let key in navObj.list[0].list) {
          let info = navObj.list[0].list[key];
          if (info._id == curRouter.params.id) {
            toInfo(info);
            return;
          }
        }
      }
    }
  }

}

function getListByFlag(flag) {
  let arr = [];
  for (let key in navObj.list[0].list) {
    let info = navObj.list[0].list[key];
    for (let value of info.tag) {
      if (flag == value) {
        arr.push(info);
      }
    }
  }
  return arr;
}

function getLabelList(list) {
  let arr = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i].Status == 1) {
      arr.push(list[i]);
    }
  }
  return arr;
}
function getAllList() {
  let arr = [];
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key];
    if (info.type == curTag && info.status) {
      let nowTime = Date.now();
      let startTime = Date.parse(info.openTime[0].replace(/-/g, '/'));
      let endTime = Date.parse(info.openTime[1].replace(/-/g, '/'));
      if (nowTime > startTime && nowTime < endTime) {
        arr.push(info);
      }
    }
  }
  arr.sort((a, b) => {
    return Number(a.sort) - Number(b.sort);
  })
  return arr;
}

function getBannerImg(item) {
  if (tools.isMobile()) {
    return comApi.getNormalImg(tools.getCurLangValue(item.iconMobile));
  } else {
    return comApi.getNormalImg(tools.getCurLangValue(item.icon));
  }
}

function getPromotionTag(item) {
  let str = '';
  for (let value of item.tag) {
    for (let key in curTypeLabelList) {
      let info = curTypeLabelList[key];
      if (info.Navigate == value) {
        str += tools.getCurLangValue(info.Text) + ','
      }
    }
  }
  return str.slice(0, -1);
}

function getPromotionFactory(item) {
  let str = '';
  for (let value of item.factoryList) {
    str += tools.getFactoryName(value) + ','
  }
  return str.slice(0, -1);
}


function itemClick(item, index) {
  navObj.active = index;
}

function toInfo(item) {
  bus.emit(bus.event.promotionPop, { bool: true, data: item })
}

</script>

<style lang="scss" scoped>
.root {
  .x-cover {
    background-image: url("@/assets/pc/sport/sport-cover-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 400px;
    overflow: hidden;
    z-index: 1;
  }

  .x-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));
  }

  .promo-menu {
    background: #f4f4fe;
    padding: 10px 0;
    width: 100%;
    position: relative;

    .menu-fluid {
      display: flex;
      *zoom: 1;
      padding-right: 80px;
      padding-left: 80px;
      justify-content: space-between;

      h2 {
        font-size: 30px;
        font-weight: 500;
      }

      .title-left {
        overflow: hidden;
        position: relative;
        margin: 0;
        line-height: 40px;
        color: #0f161f;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .title-left:before {
        content: "";
        width: 60px;
        height: 40px;
        display: inline-block;
        background: url("@/assets/pc/promotion/promotion-btn.png");
        vertical-align: top;
        // -webkit-filter: grayscale(100%) brightness(200%);
        // filter: grayscale(100%) brightness(200%);
      }

      .title-right {
        overflow: hidden;
        position: relative;
        padding: 0 50px;
        display: flex;
        align-items: center;
        gap: 10px;

        .item {
          display: block;
          position: relative;
          padding: 0 15px;
          font-size: 16px;
          text-align: center;
          line-height: 30px;
          color: #ccc;
          white-space: nowrap;
        }

        .item::before {
          content: "";
          width: 90%;
          height: 100%;
          // background: linear-gradient(180deg,#cc68ff,#5858ff);
          background: hsla(252, 65%, 66%, 0.106);
          border-radius: 5px;
          position: absolute;
          left: 5%;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: -webkit-transform 0.3s ease;
          transition: -webkit-transform 0.3s ease;
          transition: transform 0.3s ease;
          transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        }

        .active::before,
        .item:hover::before {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }

        .active,
        .item:hover {
          color: #0f161f;
          text-decoration: none;
        }
      }
    }
  }

  .container-fluid {
    *zoom: 1;
    padding-right: 80px;
    padding-left: 80px;

    .promo-list {
      min-height: 200px;
      padding: 50px 0;

      > li {
        .promo-list-block {
          *zoom: 1;
          background-image: linear-gradient(-2deg, #fff 4%, #fff 100%),
            linear-gradient(#fff, #fff);
          border-radius: 5px;
          padding: 20px;
          position: relative;
          overflow: hidden;
          -webkit-transition: all 0.4s linear;
          transition: all 0.4s linear;

          .promo-grid {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -ms-flex-line-pack: stretch;
            align-content: stretch;

            li {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              position: relative;

              .promo-banner {
                padding-bottom: 27.6%;
                width: 100%;
                height: 0;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  margin: auto;
                  left: 0;
                  right: 0;
                }
              }

              .promo-content {
                padding: 0 0 50px 20px;

                .promo-title {
                  margin: 0 0 20px;
                  font-size: 26px;
                  line-height: 130%;

                  a {
                    color: #0f161f;
                  }
                }

                .promo-detail {
                  p {
                    word-break: break-all;
                    display: block !important;
                    margin: 0 0 10px;

                    b {
                      color: #0f161f;
                      font-weight: 700;
                    }

                    b:after {
                      content: "：";
                    }

                    span {
                      color: #0f161f;
                    }
                  }
                }
              }
            }

            li:first-child {
              width: 58%;
            }

            li:last-child {
              width: 41%;
            }
          }

          .promo-btn {
            *zoom: 1;
            overflow: hidden;
            position: absolute;
            z-index: 10;
            right: 20px;
            bottom: 20px;
            width: 12%;

            a {
              display: block;
              background-image: linear-gradient(0deg, #0051d6 4%, #0051d6 50%),
                linear-gradient(#000000, #000000);
              box-shadow: inset 0 0 15px 0 rgba(114, 250, 250, 0.2);
              border-radius: 3px;
              padding: 5px 10px;
              color: #fff;
              font-weight: 600;
              line-height: 30px;
              text-align: center;
              text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
              text-decoration: none;
            }
          }

          .promo-btn-1 {
            bottom: 100px;

            a {
              background: red !important;
            }
          }
        }

        .promo-list-block:hover {
          background: linear-gradient(180deg, #fff, #ffbf00);
          box-shadow: hsla(0, 0%, 100%, 0.5);
          -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
          -webkit-transition: all 0.4s linear;
          transition: all 0.4s linear;
        }
      }

      > li:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .x-cover::before {
    opacity: 0;
    visibility: hidden;
  }
}

@media (min-width: 1200px) {
}
</style>