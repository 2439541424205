<template>
  <div class="mailBoxTitle">
    <div class="memberform-fuild">
      <div class="bulk-action-button-group">
        <button class="left-btn" @click="deleteAll()">
          {{ t("Delete All") }}
        </button>
        <button :class="getReceiveState() ? 'disabled' : ''" class="right-btn" @click="receiveAll()">
          {{ t("Receive All") }}
        </button>
      </div>
      <div v-if="!recordObj.loading" class="listrecord">
        <div v-if="recordObj.allList.length <= 0" class="norecord">{{t('No Record')}}</div>
        <div v-else class="even-list">
          <div class="item" v-for="(item, index) in recordObj.list">
            <div class="item-row">
              <div class="item-row-col-1">{{t("Promotions") }}</div>
              <div class="item-row-col-2">{{ item.EventType }}</div>
            </div>
            <div class="item-row">
              <div class="item-row-col-1"> {{ t("Bonus") }}</div>
              <div class="item-row-col-2">{{  tools.formatNum2k(item.Amount)}}</div>
            </div>
            <div class="item-row">
              <div class="item-row-col-1"> {{ t("Release Time") }}</div>
              <div class="item-row-col-2"> {{ tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}
                {{ tools.formatDate(item.CreateAt, "hh:mm") }}</div>
            </div>
            <div class="item-row">
              <div class="item-row-col-1"> {{ t("Received Time") }}</div>
              <div class="item-row-col-2"> {{item.ReceiveTime}}</div>
            </div>
            <div class="item-row">
              <div class="item-row-col-1">{{ t("Draw End Date") }}</div>
              <div class="item-row-col-2">{{ t(item.OverTime) }}</div>
            </div>
            <div class="item-row">
              <div class="item-row-col-1"> {{ t("Status") }}</div>
              <div class="item-row-col-2 have-button">
                <button v-if="item.DetailStatus == 'Done'" class="release-btn" @click="toReceive(item)">{{t('Received')}}</button>
                <span v-else> {{ t(item.DetailStatus) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
          <el-pagination background layout="prev, pager, next" :pager-count="5" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);

const curType = "";
const recordObj = reactive({
  allList: [],
  list: [],
  mailUnreadNum: 0,
  loading: false,
});

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 10,
  total: 0,
});

if (isLogged.value) {
  getRecord();
}

bus.on('onConnected', () => {
  getRecord();
})

function getRecord() {
  let parms = {
  };
  mqant.request(topic.actionGetManualAwardList, parms, function (data, topicName, msg) {
    log.info("actionGetManualAwardList--", data.Data);
    if (data.Code == 0) {
      recordObj.allList = data.Data;
      //记录总数
      paginationObj.total = recordObj.allList.length;
      //分页截取
      sliceArr();
      recordObj.loading = false;
    } else {
    }
  });
}

function getDeleteState() {
  if (recordObj.allList.length <= 0) {
    return true;
  }
  let allReceive = true;
  for (let key in recordObj.allList) {
    if (recordObj.allList[key].DetailStatus !== "Done") {
      allReceive = false;
    }
  }
  if (allReceive) {
    return true;
  }
  return false;
}

function getReceiveState() {
  if (recordObj.allList.length <= 0) {
    return true;
  }
  for (let key in recordObj.allList) {
    if (recordObj.allList[key].DetailStatus == "Done") {
      return false;
    }
  }
  return true;
}


function receiveAll() {
  if (getReceiveState()) return;
  ElMessageBox.confirm(
    t("Are you sure you want to receive all?"),
    t("Receive All"),
    {
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
      type: "warning",
    }
  )
    .then(() => {
      let parms = {
      };
      log.info("parms--", parms);
      mqant.request(topic.actionReceiveManualAward, parms, function (data, topicName, msg) {
        log.info("actionReceiveManualAward--", data.Data);
        if (data.Code == 0) {
          getRecord();
        } else {
        }
      });
    })
    .catch(() => { });
}
function deleteAll() {
  if (getDeleteState()) return;
  ElMessageBox.confirm(
    t("Are you sure you want to delete all?"),
    t("Delete All"),
    {
      confirmButtonText: t("Confirm"),
      cancelButtonText: t("Cancel"),
      type: "warning",
    }
  )
    .then(() => {
      let parms = {
      };
      log.info("parms--", parms);
      mqant.request(topic.actionDeleteManualAward, parms, function (data, topicName, msg) {
        log.info("actionDeleteManualAward--", data.Data);
        if (data.Code == 0) {
          getRecord();
        } else {
        }
      });
    })
    .catch(() => { });
}

function toReceive(item) {
  let parms = {
    Oid: item.oid,
  }
  log.info('parms--', parms)
  mqant.request(topic.actionReceiveManualAward, parms, function (data, topicName, msg) {
    log.info('actionReceiveManualAward--', data.Data)
    if (data.Code == 0) {
      getRecord();
      ElMessage.success(t('Operation successful'));
    } else {
    }
  });
}

const handleSizeChange = (val) => {
  sliceArr();
};
const handleCurrentChange = (val) => {
  sliceArr();
};
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}


</script>

<style lang="scss" scoped>
.mailBoxTitle {
  position: relative;
  color: #333;

  .memberform-fuild {
    border-radius: 10px;
    padding: 20px;
    background: #fff;

    .bulk-action-button-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 20px;

      .left-btn,
      .right-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 5px 20px;
        min-width: 120px;
        width: 50%;
        min-height: 40px;
        font-size: 14px;
        text-align: center;
        border: none;
        border-radius: 5px;
      }

      .disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
      }

      .left-btn {
        margin-right: 15px;
        color: #353535;
        border: 1px solid #ffbf00 !important;
      }

      .right-btn {
        color: #fff;
        background: #ffbf00;
      }
    }

    .listrecord {
      margin-bottom: 20px;

      .norecord {
        border-radius: 5px;
        border: 1px solid #dbe0e4;
        margin-bottom: 20px;
        background: #fff;
        padding: 50px 10px;
        text-align: center;
      }

      .even-list {
        .item {
          border-radius: 5px;
          border: 1px solid #dbe0e4;
          margin-bottom: 20px;

          .item-row {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #dbe0e4;

            .item-row-col-1 {
              padding: 10px;
              font-weight: 600;
              width: 40%;
              text-align: center;
              border-right: 1px solid #dbe0e4;
            }

            .item-row-col-2 {
              position: relative;
              padding: 10px;
              font-weight: 600;
              width: 60%;
              text-align: center;
              word-break: break-all;
            }
            .have-button {
              padding: 0;
              .release-btn {
                // position: absolute;
                // left: 50%;
                // top: 50%;
                // transform: translate(-50%, -50%);
                width: 80%;
                max-height: 100%;
                padding: 5px;
                font-size: 14px;
                color: #fff;
                background: #ea9f13;
                border-radius: 3px;
                border: 0;
                cursor: pointer;
              }
            }
          }

          .item-row:last-child {
            border-bottom: none;
          }
        }
      }

      .list-th {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: #f7f7f7;
        border: 1px solid #dbe0e4;

        > .el-col {
          border-right: 1px solid #dbe0e4;
          font-weight: 600;
        }
      }

      .list-td {
        background: #fff;
        border: 1px solid #dbe0e4;
        border-top: 0;

        > .el-col {
          // border-right: 1px solid #dbe0e4;
          padding: 0;
        }
      }

      .el-row {
        .el-col {
          display: table-cell;
          float: none;
          vertical-align: middle;
          padding: 10px;
          text-align: center;
          position: relative;

          .el-col-div {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .pagination-wrapper {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: center;

      :deep(.el-pagination) {
        .is-active {
          background-color: #6c5f4e !important;
        }
      }
    }
  }
}
</style>