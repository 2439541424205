<template>
  <div class="depositRecord">
    <div class="money-box">
      <div class="searchway-block">
        <div class="searchway-date">
          <div class="searchway-title">
            <span>{{t('Search Time :')}}</span>
          </div>
          <div class="searchway-btn">
            <div v-for="(item, index) in timeObj.list" class="todayBtn" :class="timeObj.active == index ? 'active' : ''" @click="dateItemClick(item, index)">
              <span>{{ t(item.label) }}</span>
            </div>
          </div>
        </div>
        <div class="search-select">
          <div class="select-left">
            <el-select v-model="typeObj.active" :placeholder="t('Select')" @change="selectChange">
              <el-option v-for="(item, index) in typeObj.list" :key="item" :label="t(item.label)" :value="index" />
            </el-select>
          </div>
          <div class="select-right">
            <button class="searchway-Btn" @click="searchClick()">{{t('Search')}}</button>
          </div>
        </div>
      </div>
      <div class="listrecord">
        <el-row class="hidden-xs-only list-th">
          <el-col :span="6">
            {{t('Transaction Number')}}
          </el-col>
          <el-col :span="5">
            {{t('Transaction Time')}}
          </el-col>
          <el-col :span="5">
            {{t('Transaction Amount')}}
          </el-col>
          <el-col :span="4">
            {{t('Deposit Info')}}
          </el-col>
          <el-col :span="4">
            {{t('Status')}}
          </el-col>
        </el-row>
        <div v-if="recordObj.allList.length <= 0" class="norecord">{{t('No Record')}}</div>
        <div v-else class="even-list">
          <el-row v-for="(item, index) in recordObj.list" class="list-td">
            <el-col :span="6">
              <div class="el-col-div"> {{ item.OrderId }}</div>
            </el-col>

            <el-col :span="5">
              <div class="el-col-div"> {{ $tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}
                {{ $tools.formatDate(item.CreateAt, "hh:mm") }}</div>
            </el-col>
            <el-col :span="5">
              <div class="el-col-div"> {{ $tools.formatNum2k(item.Amount) }}</div>
            </el-col>
            <el-col :span="4">
              <div class="el-col-div">{{ item.Remark }}</div>
            </el-col>
            <el-col :span="4">
              <div class="el-col-div" v-if="item.Status == 4" style="color:red">{{
                                getTextByStatus(item.Status) }}</div>
              <div class="el-col-div" v-else-if="item.Status == 9">{{ getTextByStatus(item.Status) }}</div>
              <div class="el-col-div" v-else style="color:green">{{
                                (item.Status) }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
        <el-pagination background layout="prev, pager, next" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
import { Search } from "vant";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const typeObj = reactive({
  value: 'All',
  active: 0,
  list: [
    { label: 'All', type: 'all' },
    { label: 'Success', type: 'success' },
    { label: 'Reject', type: 'reject' },
    { label: 'Verifying', type: 'verifying' },
  ]
})

const timeObj = reactive({
  active: 0,
  model: tools.getDateByType('today'),
  list: [
    { label: 'Today', type: 'today' },
    { label: 'Yesterday', type: 'yesterday' },
    { label: 'Last Seven Days', type: 'pastWeek' },
    { label: 'Last Week', type: 'lastWeek' },
    { label: 'This Month', type: 'month' },
    { label: 'Last Month', type: 'lastMonth' },
  ],
})

const recordObj = reactive({
  allList: [],
  list: []
})

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 10,
  total: 0,
})

if (isLogged.value) {
  getRecord()
}
bus.on('onConnected', () => {
  getRecord();
})
function getRecord() {
  let startDate = timeObj.model[0].Format("yyyy-MM-dd") + ' 00:00:00';
  let endDate = timeObj.model[1].Format("yyyy-MM-dd") + ' 23:59:59';
  let parms = {
    TradeType: 'order',
    startDate: startDate,
    endDate: endDate
  }
  log.info('parms--', parms)
  mqant.request(topic.billRecord, parms, function (data, topicName, msg) {
    log.info('billRecord--', data.Data)
    if (data.Code == 0) {
      recordObj.allList = getAllListByType(data.Data.data);
      //记录总数
      paginationObj.total = recordObj.allList.length;
      //分页截取
      sliceArr();
    } else {
    }
  });
}

function getAllListByType(data) {
  let arr = [];
  if (typeObj.active == 0) { //所有
    arr = data;
  } else if (typeObj.active == 1) {//成功
    data.forEach(info => {
      if (info.Status == 9) {
        arr.push(info);
      }
    });
  } else if (typeObj.active == 2) {//失败
    data.forEach(info => {
      if (info.Status == 4) {
        arr.push(info);
      }
    });
  } else if (typeObj.active == 3) {//处理中
    data.forEach(info => {
      if (info.Status !== 4 && info.Status !== 9) {
        arr.push(info);
      }
    });
  }
  return arr;
}
const handleSizeChange = (val) => {
  sliceArr();
}
const handleCurrentChange = (val) => {
  sliceArr();
}
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}

function searchClick() {
  getRecord();
}

function selectChange(value) {
  typeObj.active = value;
  getRecord();
}
function dateItemClick(item, index) {
  timeObj.active = index;
  timeObj.model = tools.getDateByType(item.type);
  getRecord();
}


function getTextByStatus(status) {
  let str = '';
  if (status == 4) {
    str = t('Reject');
  }
  else if (status == 9) {
    str = t('Success');
  } else {
    str = t('Verifying')
  }
  return str;
}

</script>

<style lang="scss" scoped>
.depositRecord {
  .money-box {
    margin-left: -15%;
    margin-right: -15%;
    color: #333333;

    .searchway-block {
      *zoom: 1;
      margin-bottom: 30px;

      .searchway-date {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;

        .searchway-title {
          padding: 10px 10px 10px 0;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          white-space: nowrap;

          span {
            color: #0f161f;
            font-size: 16px;
          }
        }

        .searchway-btn {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          width: 100%;

          span {
            color: #0f161f;
          }

          .active {
            opacity: 1 !important;
            border: 2px solid #ffbf00 !important;
            background-color: #ffbf00;
            color: #fff;
            span {
              color: #fff;
            }
          }

          .active + div {
            border-left: none;
          }
        }

        .searchway-btn > [class*="Btn"]:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .searchway-btn > [class*="Btn"]:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-right: 2px solid #d8c6e5;
        }

        .searchway-btn > [class*="Btn"] {
          width: 100%;
          text-align: center;
          font-size: 16px;
          padding: 10px;
          border: 2px solid #d8c6e5;
          border-right: none;
          opacity: 0.3;
        }
      }

      .search-select {
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        align-items: center;

        .select-left {
          position: relative;
          padding-left: 15px;
          padding-right: 15px;
          width: 33%;

          :deep(.el-select) {
            font-size: 14px;

            .el-input__wrapper {
              box-shadow: none;
              border: none;
              border-radius: 0;
              border-bottom: 1px solid #f6dbff;
            }

            .el-input__inner {
              height: 50px !important;
              color: #616161;
              background: #fff;
            }

            .el-input--suffix .el-input__inner {
              padding-right: 30px;
            }
          }
        }

        .select-right {
          padding-left: 15px;
          padding-right: 15px;
          width: 25%;

          .searchway-Btn {
            position: relative;
            text-align: center;
            cursor: pointer;
            color: #999;
            font-size: 16px;
            padding: 10px 15px;
            line-height: 30px;
            border-radius: 5px;
            border: 1px solid #dbe0e4;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
            background-image: -webkit-gradient(
              linear,
              left top,
              left bottom,
              color-stop(50%, #fff),
              to(#eee)
            );
            background-image: linear-gradient(180deg, #fff 50%, #eee);
            background-repeat: repeat-x;
          }

          .searchway-Btn:focus,
          .searchway-Btn:hover {
            border-color: #ffbf00;
          }

          .searchway-Btn:after {
            font-family: glyphicon;
            content: "";
            font-weight: 600;
            margin-left: 10px;
            color: #ffbf00;
          }
        }
      }
    }

    .listrecord {
      margin-bottom: 30px;

      .norecord {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background: #fff;
        color: #0f161f;
        border: 1px solid #dbe0e4;
        border-top: 0;
        padding: 50px 10px;
        text-align: center;
      }

      .list-th {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: #f7f7f7;
        border: 1px solid #dbe0e4;

        > .el-col {
          border-right: 1px solid #dbe0e4;
          font-weight: 600;
        }
      }

      .list-td {
        background: #fff;
        border: 1px solid #dbe0e4;
        border-top: 0;

        > .el-col {
          border-right: 1px solid #dbe0e4;
          padding: 0;
        }
      }

      .el-row {
        .el-col {
          display: table-cell;
          float: none;
          vertical-align: middle;
          padding: 10px;
          text-align: center;
          position: relative;

          .el-col-div {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .pagination-wrapper {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: center;

      :deep(.el-pagination) {
        .is-active {
          background-color: #6c5f4e !important;
        }
      }
    }
  }
}
</style>