<template>
  <div class="personal">
    <MemberTitle :title="t('Personal Information')" />
    <div v-if="!obj.loading" class="memberform-wrap">
      <div class="base-info">
        <div class="my-3">
          <div class="x-profile-image">
            <img src="@/assets/pc/common/member_icon.png" alt="">
          </div>
        </div>
        <div class="my-3">
          <div class="-text-username">{{t('Username')}}:{{obj.UserName}}</div>
          <a @click="obj.showPass = !obj.showPass">
            <u>{{t('Change Password')}}</u>
          </a>
        </div>
        <div v-if="obj.showPass" class="password-wrap">
          <div class="form-group">
            <div class="FinalForm">
              <div class="form-group">
                <h4><span>{{t('Original password')}}</span></h4>
                <div class="FinalField">
                  <div class="form-wrap">
                    <input :type="ruleForm.showOldPass ? 'text' : 'password'" v-model="ruleForm.oldPass" autocomplete="off" class="form-input form-password" @input="validateOldPassword()">
                    <span class="icon">
                      <img v-if="ruleForm.showOldPass" src="@/assets/pc/pop/eye_hide.png" alt="" @click="ruleForm.showOldPass = !ruleForm.showOldPass">
                      <img v-else src="@/assets/pc/pop/eye_show.png" alt="" @click="ruleForm.showOldPass = !ruleForm.showOldPass">
                    </span>
                  </div>
                  <div ref="oldPassRef" v-show="oldPassShow" class="poperrorMsg"></div>
                </div>
              </div>
              <div class="form-group">
                <h4><span>{{t('New password')}}</span></h4>
                <div class="FinalField">
                  <div class="form-wrap">
                    <input v-model="ruleForm.newPass" :type="ruleForm.showNewPass ? 'text' : 'password'" autocomplete="off" class="form-input form-password" @input="validateNewPassword()">
                    <span class="icon">
                      <img v-if="ruleForm.showNewPass" src="@/assets/pc/pop/eye_hide.png" alt="" @click="ruleForm.showNewPass = !ruleForm.showNewPass">
                      <img v-else src="@/assets/pc/pop/eye_show.png" alt="" @click="ruleForm.showNewPass = !ruleForm.showNewPass">
                    </span>
                  </div>
                  <div ref="newPassRef" v-show="newPassShow" class="poperrorMsg"></div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <h4><span>{{t('Confirm new password')}}</span></h4>
              <div class="FinalField">
                <div class="form-wrap">
                  <input v-model="ruleForm.checkPassword" :type="ruleForm.showCheckPass ? 'text' : 'password'" autocomplete="off" class="form-input form-password" @input="validateCheckPassword()">
                  <span class="icon">
                    <img v-if="ruleForm.showCheckPass" src="@/assets/pc/pop/eye_hide.png" alt="" @click="ruleForm.showCheckPass = !ruleForm.showCheckPass">
                    <img v-else src="@/assets/pc/pop/eye_show.png" alt="" @click="ruleForm.showCheckPass = !ruleForm.showCheckPass">
                  </span>
                </div>
                <div ref="checkPassRef" v-show="checkPassShow" class="poperrorMsg"></div>
              </div>
            </div>
          </div>
          <div class="submitbtn-wrap">
            <button @click="submitPassForm()">{{t('Confirm')}}</button>
          </div>
        </div>
      </div>
      <div class="-bank-info-container">
        <div class="mydoudou-flex">
          <div v-for="(item,index) in obj.data.MyDouDouBT" class="x-customer-bank-info-container -v2">
            <div class="media m-auto">
              <img :src="item.Icon" alt="">
              <div class="-content-wrapper">
                <span class="-name">{{item.AccountName}}</span>
                <span class="-number">{{item.CardNum}}</span>
              </div>
            </div>
          </div>
        </div>
        <a v-if="obj.data.MyDouDouBT.length < Number(obj.data.MaxBindBankCnt)" @click="obj.showBank = !obj.showBank">
          <u>{{t('Payment Info')}}</u>
        </a>
        <div v-if="obj.showBank" class="upLoadForm">
          <div>
            <div class="form-group">
              <h4><span>{{t('Full Name')}}</span></h4>
              <div class="input-group">
                <div>
                  <input v-model="obj.FullName" :disabled="obj.data.MyDouDouBT.length > 0" class="form-input">
                </div>
              </div>
            </div>
            <div class="form-group">
              <h4><span>{{t('Account Number')}}</span></h4>
              <div class="input-group">
                <div>
                  <input v-model="obj.CardNum" class="form-input" @input="validateCardNum()">
                  <div ref="cardNumRef" v-show="cardNumShow" class="poperrorMsg">
                    {{t('Required')}}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <h4><span>{{t('Member Account Type')}}</span></h4>
              <div class="input-group">
                <div>
                  <input v-model="obj.BtName" class="form-input" @click="toSelectBank()">
                </div>
              </div>
            </div>
          </div>
          <div class="submitbtn-wrap">
            <button type="submit" @click="submitBankForm()">{{t('Save Information')}}</button>
          </div>
        </div>
      </div>
      <div class="x-admin-contact text-center ">
        <span class="x-text-with-link-component">
          <label class="-text-message ">{{t('Encountering problems')}}</label>
          <a class="-link-message" @click="forgotpass()">
            <u>{{t('Please contact customer service')}}</u>
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
import comApi from "../../../../utils/comApi";
const { t, locale } = useI18n();
const { isLogged, wallet, basicInfo } = useGetters("tcp", ["isLogged", "wallet", "basicInfo"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);

const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const [realNameRef, cardNumRef, sexRef] = [ref(), ref(), ref()];
const [realNameShow, cardNumShow, sexShow] = [ref(false), ref(false), ref(false)];

const [oldPassRef, newPassRef, checkPassRef] = [ref(), ref(), ref()];
const [oldPassShow, newPassShow, checkPassShow] = [ref(false), ref(false), ref(false)];

const obj = reactive({
  loading: true,
  data: {},
  UserName: '',
  FullName: '',
  douDouBtList: [],
  CardNum: '',
  BtName: '',
  showBank: false,
  showPass: false,
})

const ruleForm = reactive({
  oldPass: '',
  showOldPass: false,
  newPass: '',
  showNewPass: false,
  checkPassword: '',
  showCheckPass: false,
})

function validateOldPassword() {
  let value = ruleForm.oldPass;
  if (value === '') {
    oldPassShow.value = true;
    oldPassRef.value.innerText = t('Password is required.');
  } else {
    oldPassShow.value = false;
    oldPassRef.value.innerText = '';
  }
}

function validateNewPassword() {
  let value = ruleForm.newPass;
  if (value === '') {
    newPassShow.value = true;
    newPassRef.value.innerText = t('Password is required.');
  } else {
    newPassShow.value = false;
    newPassRef.value.innerText = '';
  }
}
function validateCheckPassword() {
  let value = ruleForm.checkPassword;
  if (value === '') {
    checkPassShow.value = true;
    checkPassRef.value.innerText = t('Password is required.');
  } else if (ruleForm.newPass !== ruleForm.checkPassword) {
    checkPassShow.value = true;
    checkPassRef.value.innerText = t('Confirmed password does not match.');
  } else {
    checkPassShow.value = false;
    checkPassRef.value.innerText = '';
  }
}

if (isLogged.value) {
  getUserInfo()
}

bus.on('onConnected', () => {
  getUserInfo();
})

bus.on(bus.event.getBankSelectInfo, (data) => {
  obj.BtName = data.BtName;
})

function getUserInfo() {
  let parms = {
  }
  mqant.request(topic.getUserInfoCq, parms, function (data, topicName, msg) {
    log.info('getUserInfoCq--', data.Data)
    if (data.Code == 0) {
      obj.data = tools.deepClone(data.Data);
      obj.UserName = obj.data.UserName;
      obj.FullName = obj.data.FullName;
      getBankList();
    } else {
    }
  });
}

function getBankList() {
  mqant.request(topic.bankList, {}, function (data, topicName, msg) {
    log.info('bankList---', data.Data)
    if (data.Code == 0) {
      obj.douDouBtList = data.Data.douDouBtList;
      obj.loading = false;
    } else {
      ElMessage.error(data.ErrMsg);
    }
  });
}


function validateFullName() {
  let value = obj.FullName;
  if (value === '') {
    realNameShow.value = true;
    realNameRef.value.innerText = t('Full Name is required');
  } else {
    realNameShow.value = false;
    realNameRef.value.innerText = '';
  }
}

function toSelectBank() {
  bus.emit(bus.event.bankSelectPop, { bool: true, data: { list: obj.douDouBtList } })
}

function validateCardNum() {
  let value = obj.CardNum;
  if (value == '') {
    cardNumShow.value = true;
    cardNumRef.value.innerText = t('The information is required.');
  } else if (isNaN(Number(value, 10))) {
    cardNumShow.value = true;
    cardNumRef.value.innerText = t('Please fill Account Number');
  }
  else {
    cardNumShow.value = false;
    cardNumRef.value.innerText = '';
  }
}

function sexSelectChange(value) {
  sexShow.value = false;
}

function submitBankForm() {
  validateCardNum();
  if (cardNumShow.value) {
    return;
  } else {
    let params = {
      btName: obj.BtName,
      cardNum: obj.CardNum,
      accountName: obj.FullName,
    };
    log.info('params--', params)

    mqant.request(topic.bindBank, params, function (data, topicName, msg) {
      if (data.Code == 0) {
        obj.BtName = '';
        obj.CardNum = '';
        comApi.setUserInfo();
        obj.showBank = false;
        getUserInfo();
      } else {
        ElMessage.error(data.ErrMsg);
      }
      toMobileVerifePop();
    });
  }
}

function submitPassForm() {
  validateOldPassword();
  validateNewPassword();
  validateCheckPassword();
  if (oldPassShow.value || newPassShow.value || checkPassShow.value) {
    return;
  } else {
    let params = {
      OldPwd: ruleForm.oldPass,
      NewPwd: ruleForm.newPass,
      ConfirmPwd: ruleForm.checkPassword,
    };
    log.info('params', params)
    mqant.request(topic.changePW, params, function (data, topicName, msg) {
      if (data.Code == 0) {
        basicInfo.value.Password = ruleForm.newPass
        ElMessage.success(t('Change successful'));
        ruleForm.oldPass = '';
        ruleForm.newPass = '';
        ruleForm.checkPassword = '';
        obj.showPass = false;
        getUserInfo();
      } else {
        ElMessage.error(data.ErrMsg);
      }
    });
  }
}
function toMobileVerifePop() {
  let needObj = lobbyInfo.value.MainCustomContent.find(info => (info.type == 'param' && info.key == 'mobileVerife'));
  let isNeed = needObj && needObj.status;
  if (isNeed && userInfo.value.Verify <= 0) {
    bus.emit(bus.event.mobileVerifePop, true);
  }
}

function countDate(val) {
  if (!val) {
    return ''
  }
  return val.split('T')[0] + ' ' + val.split('T')[1].substr(0, 8)
}
function haveBirthday(str) {
  return countDate(str).indexOf('1970-01-01') >= 0;
}
function dateFormat(dateData) {
  if (dateData == '') return ''
  var date = new Date(dateData)
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  var d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  const time = y + '-' + m + '-' + d
  return time
}

function forgotpass() {
  let url = lobbyInfo.value.CustomerInfo.customerLiveChat;
  $act.openBrowser(url)
}


</script>

<style lang="scss" scoped>
.personal {
  .memberform-wrap {
    padding: 0 15px;
    text-align: center;
    .base-info {
      text-align: center;
      .my-3 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important;
        .x-profile-image {
          img {
            width: 70px;
            max-width: 100%;
            height: auto;
          }
        }
        .-text-username {
          margin-bottom: 0.5rem;
          color: #472c59;
        }
        a {
          color: #e5a0ff;
        }
        a:hover {
          color: #9064ff;
        }
      }
      .password-wrap {
        width: 500px;
        margin: 0 auto;
        padding: 0 15px;

        .form-group {
          position: relative;
          margin-bottom: 30px;
          *zoom: 1;

          h4 {
            text-align: left;
            margin: 0;
            color: #0f161f;
            font-size: 18px;
            line-height: 30px;
            font-family: inherit;
            font-weight: 500;

            span {
              position: relative;
            }

            span:after {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background: #c00;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              position: absolute;
              top: 50%;
              z-index: 1;
              content: "";
              display: inline-block;
              right: -15px;
            }
          }
        }

        .form-wrap {
          position: relative;

          .icon {
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);

            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
            }
          }
        }

        .poperrorMsg {
          position: absolute;
          z-index: 20;
          bottom: 50px;
          right: 0;
          width: auto;
          background: #c00;
          color: #fff;
          line-height: 20px;
          padding: 5px 10px;
          border-radius: 3px;
          -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
        }

        .poperrorMsg:before {
          content: "";
          font-family: glyphicon;
          font-weight: 600;
          margin-right: 5px;
          top: 1px;
          position: relative;
        }

        .poperrorMsg:after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          margin: auto;
          right: 10px;
          bottom: -6px;
          display: block;
          border-color: #c00 transparent transparent;
          border-style: solid;
          border-width: 7px 7px 0;
        }

        .form-input {
          display: block;
          width: 100%;
          height: 50px;
          padding: 10px;
          font-size: 16px;
          line-height: 30px;
          color: #616161;
          background: #fff;
          border: none;
          border-bottom: 1px solid #dbe0e4;
          -webkit-box-shadow: none;
          box-shadow: none;
          -webkit-transition: none;
          transition: none;
          border-radius: 0;
        }

        .form-input:focus {
          color: #000;
          border-color: #f7f7f7;
          background-color: #f7f7f7;
          outline: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
        }

        .form-password {
          padding-right: 34px !important;
        }

        .member-note {
          color: #8e8ec0;
          margin-bottom: 30px;
        }

        .member-note:before {
          content: "";
          margin-right: 10px;
          top: 2px;
          position: relative;
          font-family: glyphicon;
        }

        .submitbtn-wrap {
          text-align: center;
          margin-bottom: 10px;
          button {
            background-image: linear-gradient(0deg, #ffbf00 4%, #ffbf00 100%),
              linear-gradient(#9b9b9b, #9b9b9b);
            background-repeat: repeat-x;
            transition: all 0.5s ease;
            cursor: pointer;
            display: inline-block;
            width: auto;
            height: auto;
            padding: 10px 40px;
            font-size: 18px;
            color: #fff;
            line-height: 30px;
            border: none;
            border-radius: 50px;
            margin: 0 auto;
          }

          button:hover {
            // background-image: linear-gradient(180deg, #1a9090 0, #2bb);
            background-repeat: repeat-x;
          }
        }
      }
    }
    .-bank-info-container {
      width: 500px;
      padding: 0;
      background-color: transparent;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .mydoudou-flex {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        .x-customer-bank-info-container {
          width: 100%;
          padding: 0.8rem 1rem;
          border-radius: 5px;
          background: #fff;
          .media {
            justify-content: center;
            align-items: center;
            margin: auto !important;
            display: flex;
            img {
              width: 60px;
              height: auto;
              margin-right: 10px;
              border-radius: 50% !important;
            }
            .-content-wrapper {
              display: flex;
              flex-direction: column;
              text-align: left;
              .-name {
                font-size: 0.875rem;
                font-weight: 500;
                color: #606b7d;
              }
              .-number {
                font-size: 1.125rem;
                font-weight: 600;
                color: #29313e;
              }
            }
          }
        }
      }
      a {
        color: #9064ff;
      }
      a:hover {
        color: #e5a0ff;
      }
      .upLoadForm {
        width: 500px;
        margin: 0 auto;
        .form-group {
          position: relative;
          margin-bottom: 30px;
          *zoom: 1;

          h4 {
            text-align: left;
            margin: 0;
            color: #0f161f;
            font-size: 18px;
            line-height: 30px;
            font-family: inherit;
            font-weight: 500;

            span {
              position: relative;
            }

            span:after {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background: #c00;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              position: absolute;
              top: 50%;
              z-index: 1;
              content: "";
              display: inline-block;
              right: -15px;
            }

            i {
              color: #f55;
              margin-left: 5px;
              cursor: pointer;
            }
          }

          .input-group {
            *zoom: 1;
            position: relative;
            display: table;
            width: 100%;
            border-collapse: separate;

            :deep(.el-date-editor) {
              width: 100%;
              height: 50px;

              .el-input__wrapper {
                font-size: 16px;
                box-shadow: none;
                border-radius: 0;
                border-bottom: 1px solid #dbe0e4;

                .el-input__icon {
                  font-size: 20px;
                }
              }

              .is-focus {
                color: #000;
                border-color: #f7f7f7;
                background-color: #f7f7f7;
                outline: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
              }
            }

            :deep(.el-select) {
              width: 100%;

              .el-input__wrapper {
                box-shadow: none;
                border: none;
                border-bottom: 1px solid #dbe0e4;
                border-radius: 0;

                .el-input__inner {
                  height: 50px !important;
                  display: block;
                  width: 100%;
                  padding: 10px;
                  font-size: 16px;
                  line-height: 30px;
                  color: #616161;

                  -webkit-box-shadow: none;
                  box-shadow: none;
                  -webkit-transition: none;
                  transition: none;
                  border-radius: 0;
                }
              }
              .is-focus {
                color: #000;
                border-color: #f7f7f7;
                background-color: #f7f7f7;
                outline: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
                .el-input__wrapper {
                  box-shadow: none !important;
                }
              }
              .el-input--suffix .el-input__inner {
                padding-right: 30px;
              }
            }
          }

          .form-input {
            display: block;
            width: 100%;
            height: 50px;
            padding: 10px;
            font-size: 16px;
            line-height: 30px;
            color: #616161;
            background: #fff;
            border: none;
            border-bottom: 1px solid #dbe0e4;
            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-transition: none;
            transition: none;
            border-radius: 0;
          }

          .form-input[disabled] {
            color: #aaa;
            background: #f3f3f3;
            border-radius: 5px;
            border: 0 solid transparent;
            opacity: 1;
            -webkit-text-fill-color: #aaa;
            cursor: not-allowed;
          }

          .form-input:focus {
            color: #000;
            border-color: #f7f7f7;
            background-color: #f7f7f7;
            outline: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          .poperrorMsg {
            position: absolute;
            z-index: 20;
            bottom: 50px;
            right: 0;
            width: auto;
            background: #c00;
            color: #fff;
            line-height: 20px;
            padding: 5px 10px;
            border-radius: 3px;
            -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
          }

          .poperrorMsg:before {
            content: "";
            font-family: glyphicon;
            font-weight: 600;
            margin-right: 5px;
            top: 1px;
            position: relative;
          }

          .poperrorMsg:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            margin: auto;
            right: 10px;
            bottom: -6px;
            display: block;
            border-color: #c00 transparent transparent;
            border-style: solid;
            border-width: 7px 7px 0;
          }
        }
        .submitbtn-wrap {
          text-align: center;
          font-size: 16px;

          button {
            background-image: linear-gradient(0deg, #ffbf00 4%, #ffbf00 50%),
              linear-gradient(#9b9b9b, #9b9b9b);
            background-repeat: repeat-x;
            transition: all 0.5s ease;
            cursor: pointer;
            display: inline-block;
            width: auto;
            height: auto;
            padding: 10px 40px;
            font-size: 18px;
            color: #fff;
            line-height: 30px;
            border: none;
            border-radius: 50px;
            margin: 0 auto;
          }

          button:hover {
            // background-image: linear-gradient(180deg, #1a9090 0, #2bb);
            background-repeat: repeat-x;
          }
        }
      }
    }
    .x-admin-contact {
      position: relative;
      margin-top: 1rem;
      padding: 5px 0;
      text-align: center;
      span {
        font-size: 1rem;
        label {
          opacity: 0.75;
          color: #333;
          margin-bottom: 0;
        }
        a {
          color: #7575ff;
          opacity: 0.75;
        }
      }
    }
  }
}
</style>