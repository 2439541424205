<template>
  <div class="betHistory">
    <div class="memberform-wrap">
      <div class="tips-box"> {{ t("Reminder：The bet history will be updated within 20 minutes.") }}</div>
      <div class="info-box">
        <div>
          {{ t("Total Bet Amount :") }}
          {{ tools.formatNum2k(paginationObj.totalBill) }}
        </div>
        <div>
          {{ t("Total Net Win :") }}
          {{ tools.formatNum2k(paginationObj.totalWin) }}
        </div>
      </div>
      <div class="gamekind-box">
        <!-- <swiper slides-per-view="auto">
                    <swiper-slide v-for="(item, index) in navObj.list" :key="item" @click="navItemClick(item, index)">
                        <a :class="index == navObj.active ? 'active' : ''">{{ item.label }}</a>
                    </swiper-slide>
                </swiper> -->
        <el-select class="form-input-el" v-model="navObj.value" placeholder="Select" @change="selectChange">
          <el-option v-for="(item, index) in navObj.list" :key="item.label" :label="t(item.label)" :value="item" />
        </el-select>
      </div>
      <div class="search-box">
        <div class="search-row">
          <div class="title">{{ t("Search Time :") }}</div>
          <div class="button">
            <div v-for="(item, index) in timeObj.list" class="btn-today" :class="timeObj.active == index ? 'active' : ''" @click="dateItemClick(item, index)">{{
                           t(item.label) }}
            </div>
          </div>
        </div>
      </div>
      <div class="list-box">
        <div class="listrecord">
          <div v-if="recordObj.allList.length <= 0" class="norecord">{{t('No Record')}}</div>
          <div v-else class="even-list">
            <div class="item" v-for="(item, index) in recordObj.list">
              <div class="item-row">
                <div class="item-row-col-1">{{t('Bet Time From')}}</div>
                <div class="item-row-col-2">{{ tools.formatDate(item.create_at, "yyyy-MM-dd") }}
                  {{ tools.formatDate(item.create_at, "hh:mm") }}</div>
              </div>
              <div class="item-row">
                <div class="item-row-col-1"> {{t('Game Brand')}}</div>
                <div class="item-row-col-2">{{ item.factory_name }}</div>
              </div>
              <div class="item-row">
                <div class="item-row-col-1">{{t('Count')}}</div>
                <div class="item-row-col-2">{{ item.cnt }}</div>
              </div>
              <div class="item-row">
                <div class="item-row-col-1">{{t('Total Bet Amount')}}</div>
                <div class="item-row-col-2">{{ tools.formatNum2k(item.valid_bet_amount) }}</div>
              </div>
              <div class="item-row">
                <div class="item-row-col-1">{{t('Total Net Win')}}</div>
                <div class="item-row-col-2">{{ tools.formatNum2k(item.income) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
          <el-pagination background layout="prev, pager, next" :pager-count="5" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import MemberTitle from "@/views/mobile/components/member/MemberTitle.vue"
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const navObj = reactive({
  active: 0,
  value: '',
  curItem: {},
  list: [
    { label: 'All', type: 'all' },
    { label: 'Slot', type: 'Slot' },
    { label: 'Casino', type: 'LiveCasino' },
    { label: 'Fish', type: 'Fish' },
    { label: 'Sport', type: 'LiveSports' },
    { label: 'Lottery', type: 'Lottery' },
    { label: 'CockFight', type: 'CockFight' },
    { label: 'Chess', type: 'Card' },
  ]
})
navObj.value = navObj.list[0].label
navObj.curItem = navObj.list[0];

const recordObj = reactive({
  allList: [],
  list: []
})

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 5,
  total: 0,
  totalBill: 0,
  totalWin: 0,
})

const timeObj = reactive({
  active: 0,
  model: tools.getDateByType('today'),
  list: [
    { label: 'Today', type: 'today' },
    { label: 'Yesterday', type: 'yesterday' },
    { label: 'Last Seven Days', type: 'pastWeek' },
    { label: 'Last Week', type: 'lastWeek' },
  ],
})

if (isLogged.value) {
  getRecord()
}
bus.on('onConnected', () => {
  getRecord()
})

function getRecord() {
  let startDate = timeObj.model[0].Format("yyyy-MM-dd") + ' 00:00:00';
  let endDate = timeObj.model[1].Format("yyyy-MM-dd") + ' 23:59:59';
  let params = {
    GameType: navObj.curItem.type,
    startDate: startDate,
    endDate: endDate
  }
  log.info('params---', params)
  mqant.request(topic.queryValidBetRecordByGameType, params, function (data, topicName, msg) {
    if (data.Code == 0) {
      recordObj.allList = []
      recordObj.allList = data.Data.BetRecord;
      log.info('recordObj.allList ', recordObj.allList)
      //记录总数
      paginationObj.total = recordObj.allList.length;
      paginationObj.totalBill = 0;
      paginationObj.totalWin = 0;
      recordObj.allList.forEach(function (item, index) {
        paginationObj.totalBill += item.valid_bet_amount;
      })
      recordObj.allList.forEach(function (item, index) {
        paginationObj.totalWin += item.income;
      })
      //分页截取
      sliceArr();
    } else {
      ElMessage.error(data.ErrMsg)
    }
  });
}


function dateItemClick(item, index) {
  timeObj.active = index;
  timeObj.model = tools.getDateByType(item.type);
  getRecord();
}

function navItemClick(item, index) {
  navObj.active = index;
  getRecord();
}

const handleSizeChange = (val) => {
  sliceArr();
}
const handleCurrentChange = (val) => {
  sliceArr();
}
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}

function selectChange(value) {
  navObj.value = value.label
  navObj.curItem = value;
  getRecord();
}

</script>

<style lang="scss" scoped>
.betHistory {
  color: #333333;

  .memberform-wrap {
    border-radius: 10px;
    padding: 20px;
    background: #fff;

    .tips-box {
      border-radius: 5px;
      background: #f7f7f7;
      border: 1px solid #f7f7f7;
      padding: 10px 20px 10px 50px;
      position: relative;
      color: #642;
      margin-bottom: 30px;
    }

    .tips-box:before {
      content: "";
      left: 20px;
      top: 20px;
      position: absolute;
      z-index: 10;
      font-family: glyphicon;
      font-size: 18px;
      font-weight: 600;
    }

    .info-box {
      margin-bottom: 20px;
      padding: 0 10px;
      color: #0f161f;
    }

    .gamekind-box {
      margin-bottom: 20px;
      padding: 0 10px;
      width: 100%;

      :deep(.el-select) {
        width: 100%;

        .el-input__wrapper {
          box-shadow: none;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #ddd;
        }

        .el-input__inner {
          height: 50px !important;
          font-size: 16px;
        }

        input {
          text-align: center;
        }

        .el-select__caret {
          font-size: 22px;
        }
      }

      :deep(.el-select) {
        font-size: 14px;

        .el-input__wrapper {
        }

        .el-input__inner {
          height: 50px !important;
          color: #616161;
          background: #fff;
        }

        .el-input--suffix .el-input__inner {
          padding-right: 30px;
        }
      }

      :deep(.swiper) {
        .swiper-slide {
          width: auto;

          a {
            display: block;
            margin: 0 2.5px;
            padding: 0 15px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-weight: 700;
            color: #0f161f;
            background-color: #ffffff;
            border-radius: 5px;
          }

          .active {
            color: #ffffff;
            background-color: #ffbf00;
          }
        }
      }
    }

    .search-box {
      margin-bottom: 20px;
      padding: 0 10px;
      color: #0f161f;

      .search-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;

        .title {
          margin-right: 4px;
          text-align: left;
        }

        .button {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-box-align: stretch;
          -ms-flex-align: stretch;
          align-items: stretch;
          width: 100%;

          .active {
            opacity: 1 !important;
            border: 2px solid #ffbf00 !important;
            background-color: #ffbf00;
            color: #fff;
            span {
              color: #fff;
            }
          }

          .active + div {
            border-left: none;
          }
        }

        .button > [class*="btn-"]:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .button > [class*="btn-"]:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-right: 2px solid #d8c6e5;
        }

        .button > [class*="btn-"] {
          opacity: 0.3;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-flex: 1;
          -ms-flex: 1 1 25%;
          flex: 1 1 25%;
          padding: 10px 8px;
          text-align: center;
          font-size: 14px;
          border: 2px solid #d8c6e5;
          border-right: none;
          cursor: pointer;
        }
      }
    }

    .list-box {
      .listrecord {
        margin-bottom: 30px;

        .norecord {
          border-radius: 5px;
          border: 1px solid #dbe0e4;
          margin-bottom: 20px;
          background: #fff;
          color: #0f161f;
          padding: 50px 10px;
          text-align: center;
        }

        .even-list {
          .item {
            border-radius: 5px;
            border: 1px solid #ddd;
            margin-bottom: 20px;

            .item-row {
              width: 100%;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #ddd;

              .item-row-col-1 {
                padding: 10px;
                font-weight: 600;
                width: 40%;
                text-align: center;
                border-right: 1px solid #ddd;
              }

              .item-row-col-2 {
                padding: 10px;
                font-weight: 600;
                width: 60%;
                text-align: center;
                word-break: break-all;
              }
            }

            .item-row:last-child {
              border-bottom: none;
            }
          }
        }

        .list-th {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          background: #f7f7f7;
          border: 1px solid #dbe0e4;

          > .el-col {
            border-right: 1px solid #dbe0e4;
            font-weight: 600;
          }
        }

        .list-td {
          background: #fff;
          border: 1px solid #dbe0e4;
          border-top: 0;

          > .el-col {
            // border-right: 1px solid #dbe0e4;
            padding: 0;
          }
        }

        .el-row {
          .el-col {
            display: table-cell;
            float: none;
            vertical-align: middle;
            padding: 10px;
            text-align: center;
            position: relative;

            .el-col-div {
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

      .pagination-wrapper {
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
        display: flex;
        justify-content: center;

        :deep(.el-pagination) {
          .is-active {
            background-color: #6c5f4e !important;
          }
        }
      }
    }
  }
}
</style>