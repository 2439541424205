<template>
  <div class="withdrawalInfo">
    <div class="main-account">
      <ul class="account-total">
        <li>
          <h4>{{t('Main Wallet')}}</h4>
          <h2> {{ tools.formatNum2k(wallet.VndBalance) }}</h2>
        </li>
      </ul>
    </div>
    <div class="tips-box">
      <div class="v-html-default overflowContent blockContent" v-html="tools.getCurLangValue(obj.payConf.Remark)">

      </div>
    </div>
    <div v-if="!obj.loading" class="FinalForm">
      <!-- <div class="form-group">
        <h4><span>{{t('Password')}}</span></h4>
        <div class="FinalField">
          <div class="form-wrap">
            <input v-model="ruleForm.passWord" :type="ruleForm.showPass ? 'text' : 'password'" autocomplete="off" class="form-input form-password" @input="validatePassword()">
            <span class="icon">
              <img v-if="ruleForm.showPass" src="@/assets/pc/pop/eye_hide.png" alt="" @click="ruleForm.showPass = !ruleForm.showPass">
              <img v-else src="@/assets/pc/pop/eye_show.png" alt="" @click="ruleForm.showPass = !ruleForm.showPass">
            </span>
          </div>
        </div>
        <div ref="passWordRef" v-show="passWordShow" class="poperrorMsg">
        </div>
      </div> -->
      <div class="form-group">
        <h4>{{t('Full Name')}}</h4>
        <input v-model="ruleForm.curBankItem.AccountName" :disabled="true" class="form-input">
      </div>
      <div class="form-group" data_vue_tag="FieldSlot">
        <h4><span>{{t('bank & account number')}}</span></h4>
        <div class="input-group">
          <div data_vue_tag="FinalField">
            <input v-if="obj.data.MyDouDouBT.length <= 0" :placeholder="t('No data')" :disabled="true" class="form-input">
            <input v-else-if="obj.data.MyDouDouBT.length ==1 " v-model="ruleForm.bankShowName" :disabled="true" class="form-input">
            <input v-else v-model="ruleForm.bankShowName" class="form-input" @click="toSelectBank()">
            <div v-show="bankNameShowErr" class="poperrorMsg">
              {{t('Required.')}}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group money-group">
        <h4><span>{{t('Fixed Amount')}}</span></h4>
        <div data_vue_tag="FinalField" class="FinalField">
          <button type="button" class="-icon-left -btn-icon" @click="changeMoney(0)">
            <i class="fas fa-minus-circle"></i>
          </button>
          <input v-model="ruleForm.money" type="number" class="form-input" :placeholder="$t('Minimum withdrawal amount') +' ' + obj.payConf.Mini / 1000" @input="validateMoney()">
          <button type="button" class="-icon-right -btn-icon" @click="changeMoney(1)">
            <i class="fas fa-plus-circle"></i>
          </button>
          <div class="x-quick-amount-percent">
            <button v-for="(item,index) in moneyObj.list" type="button" class="-percent-item" :class="moneyObj.index == index ? 'active':''" @click="moneyItemClick(item,index)">
              <span class="-percent-item-bar"></span>
              <span class="-percent-item-no">{{item.label}}</span>
            </button>
          </div>
          <div class="x-quick-amount-line"></div>
          <div ref="moneyRef" v-show="moneyShow" class="poperrorMsg">
          </div>
        </div>
      </div>
      <!-- <div class="form-group">
        <h4>{{t('Remarks (Optional)')}}</h4>
        <div class="input-group">
          <div>
            <input v-model="ruleForm.remarks" class="form-input">
          </div>
        </div>
      </div> -->
      <div class="wait-wrap">
        <img src="@/assets/pc/member/icon_wait.png" alt="">
        <span>{{$t('Withdrawals will be processed within 5 minutes.')}}</span>
      </div>
      <div class="submitbtn-wrap">
        <button type="submit" @click="submit()">{{t('Submit')}}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage, ElNotification } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet, basicInfo } = useGetters("tcp", ["isLogged", "wallet", "basicInfo"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const [passWordRef, moneyRef] = [ref(), ref()];
const [passWordShow, moneyShow, bankNameShowErr] = [ref(false), ref(false), ref(false)];

const obj = reactive({
  loading: true,
  data: {},
  payConf: {
    Remark: '',
    MethodId: ''
  }
})

const ruleForm = reactive({
  passWord: '',
  showPass: false,
  money: '',
  remarks: '',
  bankShowName: '',
  curBankItem: {
    AccountName: ''
  },
})

const moneyObj = reactive({
  index: -1,
  list: [
    { label: '25%', value: 0.25 },
    { label: '50%', value: 0.5 },
    { label: '75%', value: 0.75 },
    { label: '100%', value: 1 },]
})

if (isLogged.value) {
  getUserInfo();
  getPayInfo()
}
bus.on('onConnected', () => {
  getUserInfo();
  getPayInfo()
})
bus.on(bus.event.getBankSelectInfo, (data) => {
  ruleForm.curBankItem = data;
  let str = data.BtName + ' ' + data.CardNum;
  ruleForm.bankShowName = str;
  bankNameShowErr.value = false;
})

function getUserInfo() {
  let parms = {
  }
  mqant.request(topic.getUserInfoCq, parms, function (data, topicName, msg) {
    log.info('getUserInfoCq--', data.Data)
    if (data.Code == 0) {
      obj.data = tools.deepClone(data.Data);
      if (obj.data.MyDouDouBT.length == 1) {
        let str = obj.data.MyDouDouBT[0].BtName + ' ' + obj.data.MyDouDouBT[0].CardNum;
        ruleForm.bankShowName = str;
        ruleForm.curBankItem = obj.data.MyDouDouBT[0]
      }
      obj.loading = false;
    } else {
    }
  });
}

function getPayInfo() {
  mqant.request(topic.payInfo, {}, function (data, topicName, msg) {
    log.info('payInfo---', data.Data)
    if (data.Code == 0) {
      if (data.Data.PayConfDoudouList.length > 0) {
        obj.payConf = data.Data.PayConfDoudouList[0];
      }
    } else {

    }
  });
}

function toSelectBank() {
  bus.emit(bus.event.bankSelectPop, { bool: true, data: { list: obj.data.MyDouDouBT } })
}

function changeMoney(type) {
  //type 0,减，1:加
  let sumMoney = !ruleForm.money ? 0 : Number(ruleForm.money);
  if (type == 0) {
    sumMoney = sumMoney - 10;
    if (sumMoney <= 0) {
      sumMoney = 0
    }
  } else if (type == 1) {
    sumMoney = sumMoney + 10;
  }
  log.info('sumMoney', sumMoney);
  ruleForm.money = sumMoney;
}

function getMoneyValue(percent) {
  return Math.floor(Number(wallet.value.VndBalance / 1000) * percent);
}

function moneyItemClick(item, index) {
  moneyObj.index = index;
  ruleForm.money = getMoneyValue(item.value);
  validateMoney();
}

function submit() {
  // validatePassword();
  validateMoney();
  bankNameShowErr.value = !ruleForm.bankShowName ? true : false;
  if (moneyShow.value || bankNameShowErr.value) {
    return;
  }
  if (obj.data.MyDouDouBT.length <= 0) {
    ElNotification({
      title: t('Withdraw Warning'),
      message: t('Please select a linked bank card'),
      type: 'warning',
    })
    // router.push('/member/profile/personal')
    return;
  }

  let params = {
    doudouOid: obj.payConf.MethodId,
    amount: Number(ruleForm.money) * 1000,
    btOid: ruleForm.curBankItem.Oid ? ruleForm.curBankItem.Oid : '',
    remark: ruleForm.remarks
  };
  log.info('params', params)
  mqant.request(topic.douDouNew, params, function (data, topicName, msg) {
    log.info('douDouNew--', data.Data)
    if (data.Code == 0) {
      ElMessage.success(data.ErrMsg);
      ruleForm.passWord = '';
      ruleForm.money = '';
      ruleForm.remarks = '';
      moneyObj.index = -1;
    } else {
      ElMessage.error(data.ErrMsg);
    }
  });
}

function selectChange(value) {
  ruleForm.curBankItem = value;
  ruleForm.bankShowName = value.BtName + ' ' + value.CardNum;
}

function validatePassword() {
  let value = ruleForm.passWord;
  if (value === '') {
    passWordShow.value = true;
    passWordRef.value.innerText = t('Password is required.');
  } else if (value !== basicInfo.value.Password) {
    passWordShow.value = true;
    passWordRef.value.innerText = t('The password is incorrect');
  } else {
    passWordShow.value = false;
    passWordRef.value.innerText = '';
  }
}

function validateMoney() {
  let value = ruleForm.money;
  if (value === '') {
    moneyShow.value = true;
    moneyRef.value.innerText = t('Amount limit is required.');
  } else {
    moneyShow.value = false;
    moneyRef.value.innerText = '';
  }
}
</script>

<style lang="scss" scoped>
.withdrawalInfo {
  .main-account {
    margin-bottom: 30px;
    *zoom: 1;
    border-radius: 5px;
    border: 1px solid #dbe0e4;
    padding: 20px 0;
    position: relative;
    text-align: center;

    ul {
      position: relative;
      *zoom: 1;
    }

    .account-total {
      *zoom: 1;
      color: #666;
      text-align: center;

      li {
        display: block;
        background: url("@/assets/pc/member/wallet-icon2.png") 20px no-repeat;
        padding: 20px 10px 20px 120px;
        width: 50%;
        text-align: left;

        h4 {
          margin: 0;
          line-height: 35px;
          font-size: 18px;
          font-weight: 500;
        }

        h2 {
          color: #0f161f;
          font-weight: 600;
          word-break: break-all;
          margin: 0;
          line-height: 35px;
          font-size: 30px;
          white-space: nowrap;
        }
      }
    }
  }

  .tips-box {
    border-radius: 5px;
    background: #ffebdf;
    border: 1px solid #efebdc;
    padding: 20px 20px 20px 50px;
    position: relative;
    color: #642;
    margin-bottom: 30px;

    .overflowContent {
      overflow-x: auto;
    }
  }

  .tips-box:before {
    content: "";
    left: 20px;
    top: 20px;
    position: absolute;
    z-index: 10;
    font-family: glyphicon;
    font-size: 18px;
    font-weight: 600;
  }

  .FinalForm {
    .form-group {
      position: relative;
      margin-bottom: 30px;
      *zoom: 1;

      h4 {
        margin: 0;
        color: #0f161f;
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;

        span {
          position: relative;
        }

        span:after {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #c00;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          position: absolute;
          top: 50%;
          z-index: 1;
          content: "";
          display: inline-block;
          right: -15px;
        }
      }

      :deep(.el-select) {
        width: 100%;

        .el-input__wrapper {
          box-shadow: none;
          border: none;
          border-bottom: 1px solid #ddd;
          border-radius: 0;

          .el-input__inner {
            height: 50px !important;
            display: block;
            width: 100%;
            padding: 10px;
            font-size: 16px;
            line-height: 30px;
            color: #616161;
            background: #fff;

            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-transition: none;
            transition: none;
            border-radius: 0;
          }
        }

        .el-input--suffix .el-input__inner {
          padding-right: 30px;
        }
      }

      .form-input {
        display: block;
        width: 100%;
        height: 50px;
        padding: 10px;
        font-size: 16px;
        line-height: 30px;
        color: #616161;
        background: #fff;
        border: none;
        border-bottom: 1px solid #ddd;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        transition: none;
        border-radius: 0;
      }

      .form-input:focus {
        color: #000;
        border-color: #f7f7f7;
        background-color: #f7f7f7;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      .form-input[disabled] {
        color: #aaa;
        background: #f3f3f3;
        border-radius: 5px;
        border: 0 solid transparent;
        opacity: 1;
        -webkit-text-fill-color: #aaa;
        cursor: not-allowed;
      }

      .form-wrap {
        position: relative;

        .icon {
          position: absolute;
          display: block;
          width: 20px;
          height: 20px;
          cursor: pointer;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
          }
        }

        .form-password {
          padding-right: 34px;
        }
      }

      .poperrorMsg {
        position: absolute;
        z-index: 20;
        bottom: 50px;
        right: 0;
        width: auto;
        background: #c00;
        color: #fff;
        line-height: 20px;
        padding: 5px 10px;
        border-radius: 3px;
        -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
      }

      .poperrorMsg:before {
        content: "";
        font-family: glyphicon;
        font-weight: 600;
        margin-right: 5px;
        top: 1px;
        position: relative;
      }

      .poperrorMsg:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        margin: auto;
        right: 10px;
        bottom: -6px;
        display: block;
        border-color: #c00 transparent transparent;
        border-style: solid;
        border-width: 7px 7px 0;
      }
    }
    .money-group {
      .FinalField {
        position: relative;
        .x-quick-amount-percent {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin: 1rem 0;
          text-align: center !important;
          .-percent-item {
            width: calc((100% / 4) - 5px);
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 0;
            border: none;
            background: transparent;
            cursor: pointer;
            .-percent-item-bar {
              width: 100%;
              height: 9px;
              background: #e4efff;
            }
            .-percent-item-no {
              width: 100%;
              margin-top: 6px;
              font-size: 0.75rem;
              text-align: center;
              color: #8fcaff;
            }
          }
          .active {
            .-percent-item-bar {
              background: #3478dd;
            }
            .-percent-item-no {
              color: #3478dd;
            }
          }
        }
        .x-quick-amount-line {
          border-bottom: 1px solid #ffb9af;
        }
      }
      .form-input {
        color: #ff6b56;
        background-color: #fff;
        border: 2px solid #ffbf00;
        border-radius: 30px;
        padding-right: 50px !important;
        padding-left: 50px !important;
        font-weight: 700;
        text-align: center !important;
      }
      .form-input:focus {
        color: #ff6b56;
        border-color: #0071e3;
        background-color: #fff;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      .-icon-left {
        left: 0;
      }
      .-icon-right {
        right: 0;
        left: unset;
      }
      .-btn-icon {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 0;
        border: none;
        background: none;
        color: #0e82e4;
        font-size: 1rem;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 1.2rem;
        }
      }
    }
    .wait-wrap {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 30px;
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
      }
      span {
        color: #acaba9;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .submitbtn-wrap {
      text-align: center;

      button {
        background-image: linear-gradient(0deg, #ffbf00 4%, #ffbf00 100%),
          linear-gradient(#9b9b9b, #9b9b9b);
        background-repeat: repeat-x;
        transition: all 0.5s ease;
        cursor: pointer;
        display: inline-block;
        width: auto;
        height: auto;
        padding: 10px 40px;
        font-size: 18px;
        color: #fff;
        line-height: 30px;
        border: none;
        border-radius: 50px;
        margin: 0 auto;
      }

      button:hover {
        // background-image: linear-gradient(180deg, #1a9090 0, #2bb);
        background-repeat: repeat-x;
      }
    }
  }
}
</style>