<template>
  <div class="root">
    <ul>
      <li v-for="(item,index) in navObj.list" @click="itemClick(item,index)">
        <div class="platform-menu-img">
          <img :src="comApi.getFactoryImg(item.factoryInfo)" alt="">
        </div>
        <p>{{item.factoryInfo.Name}}</p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const navObj = reactive({
  index: 0,
  list: comApi.getCurNavListByType('CockFight'),
})


function itemClick(item, index) {
  navObj.index = index;
  router.push({
    path: '/cockFight',
    query: {
      fc: item.factoryInfo.FactoryName
    }
  })
}
</script>

<style lang="scss" scoped>
.root {
  ul {
    display: flex;
    flex-flow: wrap;
    background: #f3f5f6;
    border-radius: 5px;
    padding: 15px 0;
    li {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-starts;
      margin: 0 0 10px;
      position: relative;
      width: 25%;
      text-align: center;
      color: #0f161f;
      .platform-menu-img {
        position: relative;
        width: 95%;
        height: 0;
        padding-bottom: 145%;
        margin: 0 0 5px;
        border-radius: 5px;
        overflow: hidden;
        img {
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        position: absolute;
        border-radius: 25px;
        color: #fff;
        font-size: 12px;
        transform: scale(0.8);
        padding: 1px 5px;
        top: -5%;
        right: 0;
        text-align: center;
        background: #ff650f;
      }
    }
  }
}
</style>