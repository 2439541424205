<template>
  <div class="notice">
    <el-dialog v-model="show" draggable :destroy-on-close="true" @close="close">
      <template #header>
        <span class="el-dialog__title">{{t('Notice')}}</span>
      </template>
      <div class="announcement">
        <div class="tab-wp">
          <div v-for="(item, index) in  obj.list" class="tab" :class="obj.active == index ? 'tab-active' : ''" @click="obj.active = index">
            {{ tools.getCurLangValue(item.title) }}
          </div>
        </div>
        <div class="announceContent">
          <div class="content-html">
            <img class="banner" :src="comApi.getNormalImg(tools.getCurLangValue(obj.list[obj.active].url))" alt="">
            <div class="v-html-class" v-html="tools.getCurLangValue(obj.list[obj.active].content)"></div>
          </div>
          <div v-if="hasEvent(obj.list[obj.active])" class="submitbtn-wrap promotion-submitbtn-wrap">
            <button @click="jumpEvent(obj.list[obj.active])">{{t('APPLY')}}</button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { bus } from "@/components/core/bus";
import { ref, defineProps } from "vue";
import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import comApi from "@/utils/comApi";
import tools from "@/utils/tools";
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const show = ref(true);
const obj = reactive({
  active: 0,
  list: getNoticeList()
})

function getNoticeList() {
  let arr = [];
  for (let key in lobbyInfo.value.LobbyNotice) {
    if (lobbyInfo.value.LobbyNotice[key].status == 1) {
      arr.push(lobbyInfo.value.LobbyNotice[key]);
    }
  }
  arr.sort((a, b) => {
    return a.sort - b.sort
  })
  return arr;
}
function jumpEvent(item) {
  comApi.eventJumpByNotice(item);
  close();
}

function hasEvent(item) {
  if (Number(item.type) == 3) {
    if (Number(item.clickType) == 1) {
      return false
    }
  }
  return true
}
const close = () => {
  bus.emit(bus.event.noticeState, false);
}
</script>

<style lang="scss" scoped>
.notice {
  :deep(.el-dialog) {
    width: 95%;
    background: #ffffff;
    color: #0f161f;

    header {
      position: relative;
      text-align: left;
      box-shadow: inset 0 -1px 0 0 #d2d4ee;
      color: #fff;
      padding: 0 20px;
      width: 100%;
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      box-sizing: border-box;

      .el-dialog__title {
        line-height: 24px;
        font-size: 18px;
        color: #0f161f;
        font-weight: bold;
      }

      .el-dialog__headerbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 5px;
        text-align: center;
        font-size: 20px;

        position: absolute;
        padding: 0;
        background: 0 0;
        border: none;
        outline: none;
        cursor: pointer;

        .el-dialog__close {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #0f161f !important;
          font-size: 20px !important;
        }
      }
    }

    .el-dialog__body {
      padding: 0 !important;
      border-radius: 0 0 8px 8px;
      font-size: 14px;
      word-break: break-all;

      .announcement {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        height: 60%;
        position: relative;
        .tab-wp {
          position: relative;
          width: 100%;
          display: flex;
          border-right: 1px solid #bd722c;
          height: 55px !important;
          overflow-x: auto;
          .tab-active {
            background: linear-gradient(90deg, #bd722c, #bd722c) !important;
            color: #fff !important;
          }

          .tab-active::before {
            background: #edc592 !important;
            height: 32px !important;
            margin-top: 6px;
            content: "";
            position: absolute;
            top: 7px;
            left: 0;
            width: 4px;
            border-radius: 2px;
          }

          .tab {
            position: relative;
            width: 100%;
            line-height: 55px;
            border-bottom: 1px solid #bd722c;
            text-align: center;
            font-size: 14px;
            padding: 0 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            cursor: pointer;
            height: 55px !important;
            display: block !important;
            color: #0f161f;
          }
        }

        .announceContent {
          flex: 1;
          padding: 10px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .content-html {
            position: relative;
            overflow: auto;
            display: flex;
            flex-direction: column;
            max-height: 400px;
            .banner {
              width: 100%;
              object-fit: cover;
            }

            .v-html-class {
            }

            .v-html-class::-webkit-scrollbar {
            }
          }

          .content-html::-webkit-scrollbar {
            display: none;
          }

          .submitbtn-wrap {
            width: 100%;
            padding: 20px 0 10px;
            position: sticky;
            top: 0;
            display: block;
            left: 0;
            right: 0;
            z-index: 50;
            -webkit-transition: background-color 0.5s ease;
            transition: background-color 0.5s ease;
            -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
            text-align: center;
            animation-duration: 1.5s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: both;
            animation-name: heartBeat;
            animation-iteration-count: infinite;

            button {
              background-image: linear-gradient(0deg, #ffbf00 4%, #ffbf00 50%),
                linear-gradient(#000000, #000000);
              background-repeat: repeat-x;
              border: none;
              -webkit-box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
              box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
              -webkit-transition: all 0.5s ease;
              transition: all 0.5s ease;
              cursor: pointer;
              display: inline-block;
              width: auto;
              height: auto;
              padding: 10px 40px;
              font-size: 18px;
              color: #fff;
              line-height: 30px;
              letter-spacing: 1px;
              border-radius: 50px;
            }

            button:hover {
              background-image: linear-gradient(180deg, #ff7200 0, #ff7200);
              background-repeat: repeat-x;
            }

            @keyframes heartBeat {
              0% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }

              14% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              28% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }

              42% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              70% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
