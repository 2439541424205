<template>
  <div class="deposit">
    <MemberTitle :title="t(obj.list[obj.active].label)" />
    <div class="memberform-wrap">
      <!-- <div class="monet_save">
        <ul class="member-money-btn">
          <li v-for="(item, index) in obj.list" :class="obj.active == index ? 'active' : ''" @click="itemClick(item, index)">
            <a>{{ t(item.label) }}</a>
          </li>
        </ul>
        <router-view />
      </div> -->
      <router-view />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const obj = reactive({
  active: 0,
  list: [
    {
      label: 'VIP',
      router: '/member/vipRoot/vipInfo'
    },
    {
      label: 'Welfare Collection',
      router: '/member/vipRoot/vipWelfare'
    },
    {
      label: 'Vip History',
      router: '/member/vipRoot/vipHistory'
    }
  ]
})

//处理选中路由 
function setSelectIndex() {
  if (curRouter.path.includes('vipRoot')) {
    //选择
    for (let key in obj.list) {
      let info = obj.list[key];
      if (info.router == curRouter.path) {
        obj.active = Number(key);
      }
    }
  }
}
watch(() => {
  curRouter.path, setSelectIndex();
})

function itemClick(item, index) {
  if (item.router) {
    router.push({
      path: item.router
    })
  }
}
</script>

<style lang="scss" scoped>
.deposit {
  position: relative;

  .memberform-wrap {
    padding: 0 10% 100px;

    .monet_save {
      .member-money-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        text-align: center;
        margin-bottom: 30px;

        li {
          border-radius: 5px;
          background: #eeeeff;
          width: 30%;
          text-align: center;

          a {
            cursor: pointer;
            display: block;
            padding: 10px 2px;
            color: #8697a2;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.3;
            text-decoration: none;
            white-space: pre-wrap;
          }
        }

        .active {
          background: linear-gradient(270deg, #ffbf00 4%, #ffbf00);

          a {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>