<template>
  <div class="root">
    <ul class="top-ul">
      <li v-for="(item,index) in navObj.list" @click="itemClick(item,index)">
        <div class="platform-menu-img">
          <img :src="comApi.getFactoryImg(item.factoryInfo)" alt="">
        </div>
        <p>{{item.factoryInfo.Name}}</p>
      </li>
    </ul>
    <div class="hotmatch-section">
      <div class="top-container">
        <p class="hotmatch-title">{{t('热门赛事')}}</p>
        <div class="hotmatch-type-container">
          <button v-for="(item,index) in ballObj.list" class="type-btn" :class="ballObj.active == index ? 'active' :'' " @click="ballItemClick(index)">{{t(item.lable)}}</button>
        </div>
      </div>
      <div v-if="ballObj.list[ballObj.active].list.length > 0" class="betting-swiper">
        <div class="banner-wrapper">
          <swiper :modules="ballObj.modules" :autoplay="{ delay: ballObj.delay, disableOnInteraction: false }" :grab-cursor="true" :navigation="ballObj.navigation" :space-between="13" :slides-per-view="1.2" :speed="2000">
            <swiper-slide v-for="(item, index) in ballObj.list[ballObj.active].list" :key="item" @click="mactchClick()">
              <div>
                <div class="betContainer">
                  <div class="container-header">
                    <p class="bet-title">{{item.LeagueName}}</p>
                    <div class="header-left">
                      <p class="match-time">{{item.MatchDate}}</p>
                    </div>
                  </div>
                  <ul class="imgWarpper">
                    <li class="team-name">
                      <div class="team-home">
                        <p class="home-type">H</p>
                        <p class="team-name">{{item.HomeTeamName}}</p>
                      </div>
                      <div class="team-away">
                        <p class="away-type">A</p>
                        <p class="team-name">{{item.AwayTeamName}}</p>
                      </div>
                    </li>
                    <li class="team-img">
                      <img loading="lazy" :src="item.HomeImgUrl" class="img-home" alt="">
                      <img loading="lazy" :src="item.AwayImgUrl" class="img-away" alt="">
                    </li>
                    <li class="match-detail">
                      <div class="handicap">
                        <p class="score">{{item.HdpOddsValueAwayFt}}</p>
                        <p>{{t('盘口')}}</p>
                        <p class="score">{{item.HdpOddsValueHomeFt}}</p>
                      </div>
                      <div class="odds">
                        <p class="score">{{item.OuOddsValueAwayFt}}</p>
                        <p>{{t('赔率')}}</p>
                        <p class="score">{{item.OuOddsValueHomeFt}}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const navObj = reactive({
  index: 0,
  list: comApi.getCurNavListByType('LiveSports'),
})

//体育赛事
const ballObj = reactive({
  active: 0,
  modules: [Autoplay, Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-betting",
    prevEl: ".swiper-button-prev-betting",
  },
  list: [
    {
      lable: '足球',
      list: []
    },
    // {
    //   lable: '篮球',
    //   list: []
    // },
    // {
    //   lable: '棒球',
    //   list: []
    // }
  ]
})
setDataByType();

function ballItemClick(index) {
  ballObj.active = index;
}

function mactchClick() {
  let info = {
    FactoryName: 'apiCmd'
  }
  comApi.toFactory(info);
}

function setDataByType() {
  for (let key in lobbyInfo.value.HotMatch) {
    let info = lobbyInfo.value.HotMatch[key];
    ballObj.list[0].list.push(info);
    // if (info.SportType == "S") {
    //   //体育
    //   ballObj.list[0].list.push(info);
    // } else if (info.SportType == "BB") {
    //   //篮球
    //   ballObj.list[1].list.push(info);
    // } else if (info.SportType == "ES") {
    //   ballObj.list[2].list.push(info);
    // }
  }
  log.info('ballObj.list', ballObj)
}

function itemClick(item, index) {
  navObj.index = index;
  comApi.toFactory(item.factoryInfo)
  // router.push({
  //   path: '/sport',
  //   query: {
  //     fc: item.factoryInfo.FactoryName
  //   }
  // })
}
</script>

<style lang="scss" scoped>
.root {
  .top-ul {
    display: flex;
    flex-flow: wrap;
    background: #f3f5f6;
    border-radius: 5px;
    padding: 15px 0;
    li {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-starts;
      margin: 0 0 10px;
      position: relative;
      width: 25%;
      text-align: center;
      color: #0f161f;
      .platform-menu-img {
        position: relative;
        width: 95%;
        height: 0;
        padding-bottom: 145%;
        margin: 0 0 5px;
        border-radius: 5px;
        overflow: hidden;
        img {
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        position: absolute;
        border-radius: 25px;
        color: #fff;
        font-size: 12px;
        transform: scale(0.8);
        padding: 1px 5px;
        top: -5%;
        right: 0;
        text-align: center;
        background: #ff650f;
      }
    }
  }
  .hotmatch-section {
    margin: 30px 0 0;
    .top-container {
      display: flex;
      align-items: center;
      margin: 0 0 18px 11px;
      .hotmatch-title {
        color: #0f161f;
        font-weight: 600;
        font-size: 18px;
        margin: 0 20px 0 0;
        white-space: nowrap;
      }
      .hotmatch-type-container {
        display: flex;
        align-items: center;
        .type-btn {
          font-size: 14px;
          color: #8697a2;
          background: rgba(0, 0, 0, 0);
          padding: 5px 12px;
          border-radius: 15px;
          border: none;
          cursor: pointer;
          white-space: nowrap;
        }
        .type-btn:not(:last-child) {
          margin: 0 12px 0 0;
        }
        .active {
          color: #fff;
          background: #ffbf00;
        }
      }
    }
    .betting-swiper {
      margin: 30px 0 0;
      .banner-wrapper {
        position: relative;
        :deep(.swiper) {
          .swiper-slide {
            position: relative;
            .betContainer {
              width: 100%;
              display: inline-block;
              position: relative;
              background: #f3f5f6;
              border-radius: 5px;
              overflow: hidden;
              .container-header {
                display: grid;
                grid-template-columns: 60% 40%;
                background: linear-gradient(to right, #48125e, #73277f);
                padding: 10px 10px 10px 16px;
                font-size: 12px;
                .bet-title {
                  color: #fff;
                  overflow: hidden;
                  margin: 0;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-size: 13px;
                  max-width: 70%;
                }
                .header-left {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  .match-time {
                    margin: 0 8px 0 0;
                    color: #fff;
                    white-space: nowrap;
                  }
                }
              }
              .imgWarpper {
                display: grid;
                justify-content: space-between;
                padding: 15px 10px 17px;
                grid-template-areas: "teamName teamName teamImg teamImg" "matchDetail matchDetail matchDetail matchDetail";
                grid-template-columns: 95px 75px 95px;
                color: #0f161f;
                font-size: 12px;
                .team-name {
                  grid-area: teamName;
                  font-size: 12px;
                  .team-home {
                    margin: 0 0 10px;
                    display: flex;
                    align-items: center;
                    .home-type {
                      background: #33c600;
                      margin: 0 10px 0 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 18px;
                      height: 18px;
                      border-radius: 50%;
                      color: #fff;
                      font-size: 11px;
                    }
                    .team-name {
                      color: #0f161f;
                      margin: 0;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                  .team-away {
                    display: flex;
                    align-items: center;
                    .away-type {
                      background: #ff4e00;
                      margin: 0 10px 0 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 18px;
                      height: 18px;
                      border-radius: 50%;
                      color: #fff;
                      font-size: 11px;
                    }
                    .team-name {
                      color: #0f161f;
                      margin: 0;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                }
                .team-img {
                  grid-area: teamImg;
                  position: relative;
                  img {
                    position: absolute;
                    width: 45px;
                    height: 45px;
                    filter: drop-shadow;
                    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
                    border-radius: 50%;
                    background: #fff;
                  }
                  .img-home {
                    left: 8%;
                    top: -10%;
                    z-index: 2;
                  }
                  .img-away {
                    right: 8%;
                    bottom: -10%;
                    z-index: 1;
                  }
                }
                .match-detail {
                  grid-area: matchDetail;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 18px 0 0;
                  font-size: 12px;
                  > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 15px;
                    width: 49%;
                    background: #fff;
                    border-radius: 50px;
                    p:nth-child(2) {
                      color: #8697a2;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>