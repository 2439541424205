<template>
  <div class="loginPop">
    <el-dialog v-model="show" align-center :show-close="false" @close="close()">
      <div class="modal-content -modal-content">
        <button type="button" class="close" @click="close()">
          <i class="fas fa-remove"></i>
        </button>
        <div class="modal-header -modal-header">
          <h3 class="x-title-modal">{{t('Login')}}</h3>
        </div>
        <div class="modal-body -modal-body">
          <div class="x-login-form">
            <div class="-animatable-container animated fadeInUp">
              <div class="-animate-img -pilot-tiny">
                <img class="img-fluid -ic" src="@/assets/pc/login/ic-login.png">
              </div>
              <form class="js-login-form">
                <div class="-x-input-icon mb-3 flex-column">
                  <img class="img-fluid -icon" src="@/assets/pc/login/ic-input-zh.png">
                  <input v-model="ruleForm.userName" type="text" id="username" inputmode="text" name="username" pattern="[0-9]*" autofocus="" class="form-control x-form-control" :placeholder="t('Please enter username.')">
                </div>
                <div class="-x-input-icon flex-column">
                  <img class="img-fluid -icon" src="@/assets/pc/login/ic-input-lock.png">
                  <img v-if="showPass" class="img-eye" src="@/assets/pc/pop/eye_hide.png" @click="showPass = !showPass">
                  <img v-else class="img-eye" src="@/assets/pc/pop/eye_show.png" alt="" @click="showPass = !showPass">
                  <input v-model="ruleForm.password" :type="showPass ? 'text' : 'password'" id="password" name="password" class="form-control x-form-control" :placeholder="t('Please enter password.')">
                </div>
                <div class="-x-input-icon flex-column">
                  <img class="img-fluid -icon" src="@/assets/pc/login/ic-input-code.png">
                  <img class="code-img" :src="ruleForm.codeImg" alt="" @click="getCode()">
                  <input v-model="ruleForm.code" type="text" class="form-control x-form-control" :placeholder="t('Verification code.')">
                </div>
                <div class="x-reset-pw-text-container">
                  <a @click="forgotpass()">
                    <u>{{t('Forgot your password?')}}</u>
                  </a>
                </div>
                <div class="text-center">
                  <button type="button" class="btn btn-primary -submit f-5 f-lg-6" @click="submit()">
                    <span>{{t('Login')}}</span>
                  </button>
                </div>
              </form>
              <div class="-not-yet-member-container">
                <label class="-text-message">{{t("You don't have an account yet?")}}</label>
                <button type="button" class="-btn-register" @click="toRegister()">
                  <u>{{t('Apply for membership')}}</u>
                </button>
              </div>
              <div class="x-admin-contact">
                <span class="x-text-with-link-component">
                  <label class="-text-message ">{{t('Found a problem')}}</label>
                  <a class="-link-message " target="_blank" rel="noopener">
                    <u @click="forgotpass()">{{t('Contact customer service')}}</u>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";


const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const { uuid } = useGetters("global", ["uuid"]);
const show = ref(true);
const props = defineProps({
  data: {
    title: ''
  }
})
const showPass = ref(false);
const ruleForm = reactive({
  userName: '',
  password: '',
  codeImg: '',
  codeId: '',
  code: '',
})

getCode();
async function getCode() {
  let params = {

  };
  let rsp = await req.get(http.captcha, qs.stringify(params));
  log.info('getCode----', rsp)
  if (rsp.data.Code == 0) {
    ruleForm.codeImg = rsp.data.Data.img
    ruleForm.codeId = rsp.data.Data.id
  } else {
    ElMessage.error(rsp.data.ErrMsg);
  }
}

function submit() {
  if (!ruleForm.userName || !ruleForm.password) {
    bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('The username or password is incorrect') } })
    return;
  }
  if (!ruleForm.code) {
    bus.emit(bus.event.commonTipPop, { bool: true, data: { title: t('The verification code cannot be empty') } })
    return;
  }
  let params = {
    account: ruleForm.userName,
    password: ruleForm.password,
    platform: tools.platform(),
    uuid: uuid.value,
    uuid_web: uuid.value,
    code: ruleForm.code,
    codeId: ruleForm.codeId,
  };
  doLogin({
    params: params,
    url: http.userLogin,
  }).then(
    (resolve) => {
      ElMessage.success(t('Login succeeded'));
      close();
      router.push({ path: '/' })
    },
    (reject) => {
      log.info('reject', reject);
      getCode();
      ElMessage.error(reject.ErrMsg);
    }
  );
}

function toRegister() {
  close();
  bus.emit(bus.event.resgisterPop, true);
}

function forgotpass() {
  let url = lobbyInfo.value.CustomerInfo.customerLiveChat;
  $act.openBrowser(url)
}

function close() {
  bus.emit(bus.event.loginState, false);
}
</script>

<style lang="scss" scoped>
.loginPop {
  position: relative;

  :deep(.el-dialog) {
    position: relative;
    transition: max-width 0.6s;
    width: 500px;
    max-width: 100%;
    padding: 0;
    border: 2px solid #c9c9ff;
    border-radius: 10px;
    background: #2e1f40;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;

      .modal-content {
        .close {
          float: right;
          font-weight: 700;
          line-height: 1;
          text-shadow: 0 1px 0 #fff;
          padding: 0;
          background-color: transparent;
          border: 0;
          appearance: none;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 5;
          width: 40px;
          height: 40px;
          color: #fff;
          opacity: 0.5;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 2.5rem !important;

          .fa-remove {
            font-size: 1.25rem;
          }
        }

        .close:hover {
          opacity: 0.75;
        }

        .modal-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          margin-top: 0.5rem;
          padding: 1rem 2rem 0.5rem;
          color: #fff;
          border-bottom: none;
          text-align: center;

          .x-title-modal {
            text-align: center;
            margin: auto;
            color: #fff;
            font-weight: 800;
            line-height: 1.2;
            font-size: 1.75rem;
          }
        }

        .modal-body {
          padding: 0 4rem 0.75rem;
          margin: 0.75rem;
          position: relative;
          flex: 1 1 auto;

          .x-login-form {
            .-animate-img {
              width: 100%;
              height: 124px;
              overflow: hidden;
              position: relative;
              text-align: center;

              img {
                width: 160px;
                position: absolute;
                top: 0;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
              }
            }

            .js-login-form {
              .-x-input-icon {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                border-radius: 3px;
                position: relative;
                margin-bottom: 1rem !important;
                flex-direction: column !important;
                text-align: center;

                .img-fluid {
                  position: absolute;
                  left: 18px;
                  top: 17px;
                  color: #f22662;
                  width: 18px;
                  height: auto;
                }
                .img-eye {
                  position: absolute;
                  right: 18px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 18px;
                  height: auto;
                }
                .code-img {
                  cursor: pointer;
                  position: absolute;
                  height: 80%;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                }
                .form-control {
                  width: 100%;
                  padding-left: 45px;
                }
              }

              .x-reset-pw-text-container {
                width: 100%;
                margin: 0.75rem auto;
                text-align: right;

                a {
                  display: inline-block;
                  color: #9797ff;
                }
                a:hover {
                  color: #b1b1ff;
                }
              }

              .text-center {
                text-align: center !important;
              }

              .-submit {
                width: 100%;
                padding: 0.85rem 2.5rem;
                background: linear-gradient(180deg, #ffbf00, #ffbf00);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                  0 1px 2px hsla(0, 7.3%, 92%, 0.24);
                cursor: pointer;
                color: #fafafa;
                border-radius: 25px;
                border: none;
              }

              .-submit:not([disabled]):hover {
                background: linear-gradient(180deg, #ffbf00, #ffbf00);
              }
            }

            .-not-yet-member-container {
              margin: 1rem 0 1.5rem;
              text-align: center;

              .-text-message {
                margin: 0;
                color: #fff;
              }

              .-btn-register {
                color: #9797ff;
                border: none;
                background: transparent;
                cursor: pointer;
                border: none;
              }
            }

            .x-admin-contact {
              position: relative;
              margin-top: 1rem;
              padding: 5px 0;
              text-align: center;

              .x-text-with-link-component {
                font-size: 1rem;

                .-text-message {
                  opacity: 0.75;
                  color: #fff;
                }

                .-link-message {
                  color: #7575ff;
                  opacity: 0.75;
                }
              }
            }
          }

          .x-form-control {
            background: #fff;
            border: 1px solid #f8e4ff;
            transition: all 0.5s;
            box-shadow: none;
            font-size: 0.95rem;
          }

          .x-form-control:focus {
            box-shadow: none;
            outline: none;
            border-color: #9797ff;
          }

          .form-control {
            display: block;
            height: calc(1.5em + 1.6rem + 2px);
            padding: 0.8rem;
            font-family: Kanit, Helvetica Neue, Helvetica, Arial, sans-serif;
            font-weight: 400;
            line-height: 1.5;
            border-radius: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .modal-body {
    padding: 0 10px 0.75rem !important;
  }
}
</style>