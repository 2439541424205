<template>
  <div class="resgisterPop">
    <el-dialog v-model="show" align-center :show-close="false" @close="close()">
      <div class="modal-content -modal-content">
        <button type="button" class="close" @click="close()">
          <i class="fas fa-remove"></i>
        </button>
        <div class="modal-header -modal-header">
          <h3 class="x-title-modal">{{t('Register')}}</h3>
        </div>
        <div class="modal-body -modal-body">
          <div class="x-login-form">
            <div class="-animatable-container animated fadeInUp">
              <div class="-animate-img -pilot-tiny">
                <img class="img-fluid -ic" src="@/assets/pc/login/ic-register.png">
              </div>
              <div class="js-login-form">
                <div v-for="(item, index) in fromlist" class="-x-input-icon">
                  <input v-if="!item.showEye" v-model="item.value" :type="item.inputType" class="form-control x-form-control" :class="item.showVaild ? 'is-invalid' : ''" :placeholder="t(item.placeholder)" onkeyup="this.value=this.value.replace(/\s+/g,'')" @click="inputChange(item)">
                  <input v-else v-model="item.value" :type="item.eyeState ? 'text' : 'password'" class="form-control x-form-control" :class="item.showVaild ? 'is-invalid' : ''" :placeholder="t(item.placeholder)" onkeyup="this.value=this.value.replace(/\s+/g,'')" @click="inputChange(item)">
                  <img class="icon-img" :src="item.icon">
                  <template v-if="item.showEye">
                    <img v-if="item.eyeState" class="img-eye" src="@/assets/pc/pop/eye_hide.png" @click="item.eyeState = !item.eyeState">
                    <img v-else class="img-eye" src="@/assets/pc/pop/eye_show.png" alt="" @click="item.eyeState = !item.eyeState">
                  </template>
                  <img class="code-img" v-if="item.flagId == 'codeFlagId'" :src="item.codeImg" alt="" @click="getCode()">
                  <div v-if="item.flagId == 'mobileCodeFlagId'" class="verify_code">
                    <div class="center-wrap input-addon button">
                      <button class="popup-btn-mobile-verification" @click.stop="sendVerification(item)">
                        <span v-if="!item.mobileCodeObj.needWait">{{t('Send verification')}}</span>
                        <span v-else> {{tools.stringFormat(t('Try again in [0] seconds'),[item.mobileCodeObj.time])}}</span>
                      </button>
                    </div>
                  </div>

                  <div v-show="!item.must && item.flagId == 'inviteCodeFlagId'" class="normal-feedback">
                    {{t('Leave blank if no referral.')}}
                  </div>

                  <div v-show="item.must && item.showVaild" :id="item.flagId" class="invalid-feedback">
                  </div>
                </div>
              </div>

              <div class="text-center">
                <button type="button" class="btn btn-primary -submit f-5 f-lg-6" @click="submit()">
                  <span>{{t('确认')}}</span>
                </button>
              </div>
              <div class="x-reset-pw-text-container">
                <label class="-text-message">{{t('You already have a user account.')}}</label>
                <a class="text-muted-lighter" @click="toLogin()">
                  <u>{{t('Login')}}</u>
                </a>
              </div>
              <div class="x-admin-contact text-center ">
                <span class="x-text-with-link-component">
                  <a class="-link-message" @click="toComInfoPop(t('Official terms and conditions'))">
                    <u>{{t('Terms & Conditions')}}</u>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage, ElNotification } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";


const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin, doRegister } = useActions("user", [
  "onLogged",
  "doLogin",
  "doRegister"
]);
const { uuid, registerParams } = useGetters("global", ["uuid", "registerParams"]);
const show = ref(true);
let needAutoLogin = getStatusBykey('register_autoLogin');
const phoneNumberLen = ref(Number(lobbyInfo.value.AllRegisterConf.phoneNumberLen));
let fromConf = [
  {
    icon: require('@/assets/pc/login/ic-input-zh.png'),
    flagId: 'userNameFlagId',
    value: '',
    status: true,
    must: true,
    inputType: 'text',
    placeholder: 'Please enter username.',
    showVaild: false,
    vaildFunc: userNameVaild
  },
  {
    icon: require('@/assets/pc/login/ic-input-lock.png'),
    flagId: 'passFlagId',
    value: '',
    status: true,
    must: true,
    inputType: 'password',
    placeholder: 'Please enter password.',
    showVaild: false,
    showEye: true,
    eyeState: false,
    vaildFunc: passVaild
  },
  {
    icon: require('@/assets/pc/login/ic-input-lock.png'),
    flagId: 'confrimPassFlagId',
    value: '',
    status: true,
    must: true,
    inputType: 'password',
    placeholder: 'Confirm password again',
    showVaild: false,
    showEye: true,
    eyeState: false,
    vaildFunc: confrimPassVaild
  },
  {
    icon: require('@/assets/pc/login/yinhang_icon.png'),
    flagId: 'btNameFlagId',
    value: '',
    status: lobbyInfo.value.AllRegisterConf.RegisterBindCard > 0,
    must: true,
    inputType: 'text',
    placeholder: 'Please choose a bank.',
    showVaild: false,
    vaildFunc: btNameVaild
  },
  {
    icon: require('@/assets/pc/login/yinhang_icon.png'),
    flagId: 'cardNumFlagId',
    value: '',
    status: lobbyInfo.value.AllRegisterConf.RegisterBindCard > 0,
    must: true,
    inputType: 'text',
    placeholder: 'Please enter Account Number.',
    showVaild: false,
    vaildFunc: cardNumVaild
  },
  {
    icon: require('@/assets/pc/login/xingm_icon.png'),
    flagId: 'realNameFlagId',
    value: '',
    status: lobbyInfo.value.AllRegisterConf.RegisterBindCard > 0,
    must: true,
    inputType: 'text',
    placeholder: 'Please enter Bank Account Holder.',
    showVaild: false,
    vaildFunc: realNameVaild
  },
  {
    icon: require('@/assets/pc/login/ic-input-phone.png'),
    flagId: 'phoneFlagId',
    value: '',
    // status: getStatusBykey('register_phone'),
    status: true,
    must: true,
    inputType: 'text',
    placeholder: 'Please enter mobile number.',
    showVaild: false,
    vaildFunc: phoneVaild
  },
  {
    icon: require('@/assets/pc/login/yzm_icon.png'),
    flagId: 'mobileCodeFlagId',
    value: '',
    mobileCodeObj: {
      timeFuc: null,
      needWait: false,
      time: 180,
    },
    status: lobbyInfo.value.AllRegisterConf.RegisterPhoneVerify > 0,
    must: true,
    inputType: 'text',
    placeholder: 'Please enter mobile code.',
    showVaild: false,
    vaildFunc: mobileCodeVaild
  },
  {
    icon: require('@/assets/pc/login/ic-input-refer.png'),
    flagId: 'inviteCodeFlagId',
    value: '',
    status: getStatusBykey('register_referralCode'),
    must: false,
    inputType: 'text',
    placeholder: 'Please enter referral code',
    showVaild: false,
    vaildFunc: inviteCodeVaild
  },
  {
    icon: require('@/assets/pc/login/ic-input-code.png'),
    flagId: 'codeFlagId',
    value: '',
    codeImg: '',
    codeId: '',
    status: getStatusBykey('register_code'),
    must: true,
    inputType: 'text',
    placeholder: 'Verification code.',
    showVaild: false,
    vaildFunc: codeVaild
  },
]
//状态筛选一下
const fromlist = ref(dealStatus(fromConf));
//开始
getCode();

bus.on(bus.event.getBankSelectInfo, (data) => {
  for (let key in fromlist.value) {
    let info = fromlist.value[key];
    if (info.flagId == 'btNameFlagId') {
      info.value = data.BtName;
      info.showVaild = false;
      break;
    }
  }
})

function dealStatus() {
  let arr = [];
  for (let key in fromConf) {
    if (fromConf[key].status) {
      arr.push(fromConf[key]);
    }
  }
  return arr;
}

function isHasCode() {
  let codeObj = fromlist.value.find(info => (info.flagId == 'codeFlagId'));
  if (codeObj) {
    return true;
  } else {
    return false;
  }
}


async function getCode() {
  if (!getStatusBykey('register_code')) return;
  let params = {

  };
  let rsp = await req.get(http.captcha, qs.stringify(params));
  if (rsp.data.Code == 0) {
    setCodeData(rsp.data.Data);
  } else {
    ElMessage.error(rsp.data.ErrMsg);
  }
}

function toLogin() {
  close();
  bus.emit(bus.event.loginState, true);
}

function setCodeData(data) {
  for (let key in fromlist.value) {
    let info = fromlist.value[key];
    if (info.flagId == 'codeFlagId') {
      info.codeImg = data.img
      info.codeId = data.id
    }
  }
}

function getStatusBykey(key) {
  let needObj = lobbyInfo.value.MainCustomContent.find(info => (info.type == 'param' && info.key == key));
  return needObj && needObj.status;
}

function inputChange(item) {
  item.showVaild = false;
  if (item.flagId == 'btNameFlagId') {
    toSelectBank();
  }
  // item.vaildFunc && info.vaildFunc(true, info);
}

function submit(item) {
  for (let key in fromlist.value) {
    let info = fromlist.value[key];
    if (info.must) {
      info.vaildFunc && info.vaildFunc(true, info);
    }
  }
  let isValid = true;
  for (let key in fromlist.value) {
    let info = fromlist.value[key];
    if (info.must && info.showVaild) {
      isValid = false;
      break;
    }
  }
  if (isValid) {
    let params = {
      account: getParmsByFlagId('userNameFlagId'),
      password: getParmsByFlagId('passFlagId'),
      phone: getParmsByFlagId('phoneFlagId'),
      platform: tools.platform(),
      channel: "",
      uuid: uuid.value,
      uuid_web: uuid.value,
      code: getParmsByFlagId('codeFlagId'),
      codeId: getCodeId(),

      btName: getParmsByFlagId('btNameFlagId'),
      accountName: getParmsByFlagId('realNameFlagId'),
      cardNum: getParmsByFlagId('cardNumFlagId'),
      verifyPhone: getParmsByFlagId('mobileCodeFlagId'),
    };
    if (registerParams.value) {
      params.params = registerParams.value;
    }
    log.info('params---', params)
    if (needAutoLogin) {
      doLogin({
        params: params,
        url: http.userRegister,
      }).then(
        (resolve) => {
          ElMessage.success(t('Register succeeded'));
          close();
          router.push({ path: '/' })
        },
        (reject) => {
          log.info('reject', reject)
          getCode()
          ElMessage.error(reject.ErrMsg);
        }
      );
    } else {
      doRegister({
        params: params,
        url: http.userRegister,
      }).then(
        (resolve) => {
          ElMessage.success(t('Register succeeded'));
          close();
          router.push({ path: '/' })
        },
        (reject) => {
          log.info('reject', reject)
          getCode()
          ElMessage.error(reject.ErrMsg);
        }
      );
    }
  }
}

function getParmsByFlagId(key) {
  let curObj = fromlist.value.find(info => info.flagId == key);
  if (curObj) {
    return curObj.value
  }
  return '';
}

function getCodeId() {
  for (let key in fromlist.value) {
    let info = fromlist.value[key];
    if (info.flagId == 'codeFlagId') {
      return info.codeId
    }
  }
  return '';
}

async function sendVerification(item) {
  if (item.mobileCodeObj.needWait) {
    ElMessage.warning(t('Please try again later'));
    return;
  }
  let phone = getParmsByFlagId('phoneFlagId');
  if (!phone) {
    ElMessage.warning(t('Please enter mobile number.'));
    return;
  }
  let params = {
    phone: phone,
    area: 0,
    event: 'verifyPhone',
    language: tools.getLanguage(),
  }
  log.info('params---', params)
  let rsp = await req.post(http.smsSend, qs.stringify(params));
  if (rsp.status == 200 && rsp.data.Code == 0) {
    item.mobileCodeObj.needWait = true;
    startWaitTime(item);
    ElNotification.success({
      title: t('Success'),
      message: t('Successfully sent'),
    })
  } else {
    item.mobileCodeObj.needWait = true;
    startWaitTime(item);
  }
}

function startWaitTime(item) {
  item.mobileCodeObj.timeFuc && clearTimeout(item.mobileCodeObj.timeFuc);
  item.mobileCodeObj.timeFuc = setInterval(() => {
    if (item.mobileCodeObj.time <= 0) {
      item.mobileCodeObj.timeFuc && clearTimeout(item.mobileCodeObj.timeFuc);
      item.mobileCodeObj.needWait = false;
      item.mobileCodeObj.time = 180;
      return;
    }
    item.mobileCodeObj.time = item.mobileCodeObj.time - 1;
  }, 1000);
}

function toSelectBank() {
  bus.emit(bus.event.bankSelectPop, { bool: true, data: { list: lobbyInfo.value.AllRegisterConf.douDouBtList } })
}

function btNameVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Member Account Type is required');
  } else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function cardNumVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Account Number number is required');
  } else if (isNaN(Number(value, 10))) {
    item.showVaild = true;
    vaildDoc.innerText = t('Please enter correct mobile number');
  }
  else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function realNameVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Full Name is required');
  } else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function mobileCodeVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Mobile Code is required');
  } else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function userNameVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  const reg = /^[a-zA-Z0-9_-]{5,16}$/;
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Username is required');
  } else if (!reg.test(value)) {
    item.showVaild = true;
    document.getElementById(item.flagId).innerText
    vaildDoc.innerText = t('Username must contain 5-16 letters and numbers');
  } else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function passVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  const reg = /^[a-zA-Z0-9_-]{5,16}$/;
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Password is required');
  } else if (!reg.test(value)) {
    item.showVaild = true;
    vaildDoc.innerText = t('Please enter correct password containing at least 5-16 letters and numbers');
  } else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function confrimPassVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  let newPass = '';
  for (let key in fromlist.value) {
    if (fromlist.value[key].flagId == 'passFlagId') {
      newPass = fromlist.value[key].value;
      break;
    }
  }
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Password confirmation is required');
  } else if (newPass !== value) {
    item.showVaild = true;
    vaildDoc.innerText = t('Password does not match');
  } else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function phoneVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Mobile number is required');
  } else if (isNaN(Number(value, 10))) {
    item.showVaild = true;
    vaildDoc.innerText = t('Please enter correct mobile number');
  } else if (value.length < phoneNumberLen.value || value.length > phoneNumberLen.value + 1) {
    item.showVaild = true;
    vaildDoc.innerText = t('Please enter correct mobile number');
  } else if (value.length == phoneNumberLen.value && value[0] == '0') {
    item.showVaild = true;
    vaildDoc.innerText = t('Please enter correct mobile number');
  } else if (value.length == phoneNumberLen.value + 1 && value[0] !== '0') {
    item.showVaild = true;
    vaildDoc.innerText = t('Please enter correct mobile number');
  }
  else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function inviteCodeVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Referral code is required');
  } else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function codeVaild(isInvalid, item) {
  if (!isInvalid) return;
  let value = item.value;
  let vaildDoc = document.getElementById(item.flagId);
  if (value === '') {
    item.showVaild = true;
    vaildDoc.innerText = t('Verification code is required');
  } else {
    item.showVaild = false;
    vaildDoc.innerText = '';
  }
}

function close() {
  bus.emit(bus.event.resgisterPop, false);
}

function toComInfoPop(str) {
  let data = lobbyInfo.value.MainCustomContent.find(info => info.type == 'webRule');
  bus.emit(bus.event.comInfoPop, { bool: true, data: { title: str, data: data } });
}

</script>

<style lang="scss" scoped>
.resgisterPop {
  position: relative;

  :deep(.el-dialog) {
    position: relative;
    transition: max-width 0.6s;
    width: 800px;
    padding: 0;
    border: 2px solid #c9c9ff;
    border-radius: 10px;
    background: #2e1f40;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;

      .modal-content {
        .close {
          float: right;
          font-weight: 700;
          line-height: 1;
          text-shadow: 0 1px 0 #fff;
          padding: 0;
          background-color: transparent;
          border: 0;
          appearance: none;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 5;
          width: 40px;
          height: 40px;
          color: #fff;
          opacity: 0.5;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 2.5rem !important;

          .fa-remove {
            font-size: 1.25rem;
          }
        }

        .close:hover {
          opacity: 0.75;
        }

        .modal-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          margin-top: 0.5rem;
          padding: 1rem 2rem 0.5rem;
          color: #fff;
          border-bottom: none;
          text-align: center;

          .x-title-modal {
            text-align: center;
            margin: auto;
            color: #fff;
            font-weight: 800;
            line-height: 1.2;
            font-size: 1.75rem;
          }
        }

        .modal-body {
          padding: 0 2rem 0.45rem;
          margin: 0.45rem;
          position: relative;
          flex: 1 1 auto;

          .x-login-form {
            .-animate-img {
              width: 100%;
              position: relative;
              text-align: center;

              img {
                width: 160px;
              }
            }

            .js-login-form {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 30px;

              .-x-input-icon {
                padding: 0 10px;
                margin-bottom: 30px;
                width: 50%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                border-radius: 3px;
                position: relative;
                flex-direction: column !important;
                text-align: center;

                .icon-img {
                  position: absolute;
                  left: 25px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #f22662;
                  width: 18px;
                  height: auto;
                }
                .img-eye {
                  position: absolute;
                  right: 25px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 18px;
                  height: auto;
                }
                .code-img {
                  position: absolute;
                  height: 80%;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                }
                .verify_code {
                  position: absolute;
                  min-width: 36%;
                  max-width: 63%;
                  height: 80%;
                  display: block;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  border: 0;

                  .input-addon {
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    white-space: nowrap;
                    // border: 1px solid #ddd;
                    .popup-btn-mobile-verification {
                      background-image: -webkit-gradient(
                        linear,
                        left top,
                        left bottom,
                        from(#ea9f13),
                        to(#bb7f0f)
                      );
                      background-image: linear-gradient(
                        180deg,
                        #ea9f13 0,
                        #bb7f0f
                      );
                      background-repeat: repeat-x;
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEA9F13", endColorstr="#FFBB7F0F", GradientType=0);
                      -webkit-box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
                      box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
                      -webkit-transition: all 0.5s ease;
                      transition: all 0.5s ease;
                      cursor: pointer;
                      display: inline-block;
                      width: auto;
                      height: auto;
                      // padding: 10px;
                      font-size: 16px;
                      color: #fff;
                      line-height: 30px;
                      letter-spacing: 1px;
                      text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
                      border: 2px solid #bb7f0f;
                      width: 100%;
                      margin-bottom: 10px;
                      border-radius: 30px;
                      font-size: 16px;
                      height: 100%;
                      line-height: 20px;
                      padding: 3px 10px;
                      letter-spacing: unset;
                      white-space: break-spaces;
                      width: 100%;
                      margin: 0;
                      min-width: auto;
                    }

                    .popup-btn-mobile-verification:hover {
                      background-image: -webkit-gradient(
                        linear,
                        left top,
                        left bottom,
                        from(#bb7f0f),
                        to(#ea9f13)
                      );
                      background-image: linear-gradient(
                        180deg,
                        #bb7f0f 0,
                        #ea9f13
                      );
                      background-repeat: repeat-x;
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFBB7F0F", endColorstr="#FFEA9F13", GradientType=0);
                    }
                  }
                }
                .form-control {
                  width: 100%;
                  padding-left: 45px;
                }

                .is-invalid {
                  border-color: #f16869;
                  padding-right: calc(1.5em + 1.6rem);
                  background-image: url("@/assets/pc/login/invalid-close.svg");
                  background-repeat: no-repeat;
                  background-size: calc(0.75em + 0.8rem) calc(0.75em + 0.8rem);
                  background-position: 95% calc(0.375em + 0.4rem);
                }

                .is-invalid:focus {
                  border-color: #f16869;
                  box-shadow: 0 0 0 0.2rem rgba(241, 104, 105, 0.25);
                }

                .normal-feedback {
                  position: absolute;
                  bottom: -20px;
                  width: 100%;
                  font-size: 14px;
                }

                .invalid-feedback {
                  position: absolute;
                  bottom: -20px;
                  width: 100%;
                  font-size: 14px;
                  color: #f16869;
                }
              }
            }

            .x-reset-pw-text-container {
              text-align: center;
              margin: auto;
              margin-top: 1rem !important;
              color: #fff;
              a {
                display: inline-block;
                color: #f22662;
              }
            }

            .text-center {
              text-align: center !important;
            }

            .-submit {
              width: 50%;
              padding: 0.85rem 2.5rem;
              background: linear-gradient(180deg, #ffbf00, #ffbf00);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                0 1px 2px hsla(0, 7.3%, 92%, 0.24);
              cursor: pointer;
              color: #fafafa;
              border-radius: 25px;
              border: none;
            }

            .-submit:not([disabled]):hover {
              background: linear-gradient(180deg, #ffbf00, #ffbf00);
            }

            .-not-yet-member-container {
              margin: 1rem 0 1.5rem;
              text-align: center;

              .-text-message {
                margin: 0;
                color: #fff;
              }

              .-btn-register {
                color: #9797ff;
                border: none;
                background: transparent;
                cursor: pointer;
                border: none;
              }
            }

            .x-admin-contact {
              position: relative;
              margin-top: 1rem;
              padding: 5px 0;
              text-align: center;

              .x-text-with-link-component {
                font-size: 1rem;

                .-text-message {
                  opacity: 0.75;
                  color: #fff;
                }

                .-link-message {
                  color: #7575ff;
                  opacity: 0.75;
                }
              }
            }
          }

          .x-form-control {
            background: #fff;
            border: 1px solid #f8e4ff;
            // transition: all .5s;
            box-shadow: none;
            font-size: 0.95rem;
          }

          .x-form-control:focus {
            box-shadow: none;
            outline: none;
            border-color: #9797ff;
          }

          .form-control {
            display: block;
            height: calc(1.5em + 1.6rem + 2px);
            padding: 0.8rem;
            font-family: Kanit, Helvetica Neue, Helvetica, Arial, sans-serif;
            font-weight: 400;
            line-height: 1.5;
            border-radius: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .resgisterPop {
    :deep(.el-dialog) {
      width: 100% !important;

      .el-dialog__body {
        .modal-body {
          padding: 0 10px 0.45rem !important;

          .-x-input-icon {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>