<template>
  <div class="versionPop">
    <el-dialog v-model="show" :show-close="false" align-center :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="header-popup header-popup-border">
        <div class="header-popup-bg">
          <div class="equal-block">
            <div class="title">
              <span>{{t('Remind')}}</span>
            </div>
            <div class="messge">
              <span>{{t('Update to a new version')}} {{lobbyInfo.VersionGet.android.VersionCode}}</span>
            </div>
            <div class="submitbtn-wrap">
              <button @click="update()">{{t('Go to download')}}</button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import action from '@/components/core/action'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);

function update() {
  action.openBrowser(lobbyInfo.value.VersionGet.android.UrlPath, '')
}

</script>

<style lang="scss" scoped>
.versionPop {
  position: relative;
  z-index: 1000000;
  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    position: relative;
    width: 100%;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      overflow: visible;
      padding: 0;
      .header-popup-border {
        border-radius: 10px;
        padding: 5px;
        position: relative;

        .header-popup-bg {
          border-radius: 10px;
          position: relative;
        }

        .header-popup-bg:before {
          content: "";
          display: block;
          border-radius: 10px;
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0, #3273c6),
            to(#8b3bce)
          );
          background: linear-gradient(180deg, #3273c6 0, #8b3bce);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      .equal-block {
        color: #fff;
        padding: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .title {
          font-size: 25px;
          font-weight: bold;
        }
        .messge {
        }
        .submitbtn-wrap {
          width: 100%;
          padding: 20px 0 10px;
          position: sticky;
          top: 0;
          display: block;
          left: 0;
          right: 0;
          z-index: 50;
          -webkit-transition: background-color 0.5s ease;
          transition: background-color 0.5s ease;
          -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
          text-align: center;
          animation-duration: 1.5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: both;
          animation-name: heartBeat;
          animation-iteration-count: infinite;

          button {
            background-image: linear-gradient(0deg, #ffbf00 4%, #ffbf00 50%),
              linear-gradient(#000000, #000000);
            background-repeat: repeat-x;
            border: none;
            -webkit-box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
            box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
            cursor: pointer;
            display: inline-block;
            width: auto;
            height: auto;
            padding: 10px 40px;
            font-size: 18px;
            color: #fff;
            line-height: 30px;
            letter-spacing: 1px;
            border-radius: 50px;
          }
          @keyframes heartBeat {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }

            14% {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            28% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }

            42% {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            70% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}
</style>