<template>
  <div class="promoApply">
    <div class="memberform-fuild">
      <div v-if="recordObj.allList.length <= 0" class="nopromo">{{t('No Promotions')}}</div>
      <div v-else class="apply-list">
        <div v-for="(item, index) in recordObj.list" class="apply-item">
          <div class="item-left">
            <div class="promo-img">
              <img :src="getBannerImg(item)" alt="">
            </div>
          </div>
          <div class="item-right">
            <div class="promoContent">
              <h3>{{ tools.getCurLangValue(item.title) }}</h3>
              <div class="v-html-default description overflowContent blockContent" v-html="''">

              </div>
              <div class="apply-btn">
                <input type="button" :value="t('Detail')" class="info" @click="toInfo(item)">
                <input v-if="item.btnStatus == 0" type="button" :value="t('Apply')" @click="toPromoPop(item)">
                <input v-if="item.btnStatus == 1" type="button" :value="t('Unlock')" @click="toPromoPop(item)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="recordObj.allList.length > 0" class="pagination-wrapper">
        <el-pagination background layout="prev, pager, next" :pager-count="5" v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize" :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);

const curTag = "activityArtical";

let JoinActs = [];
let UserCanJoinActs = {};
const recordObj = reactive({
  allList: [],
  list: [],
});

const paginationObj = reactive({
  currentPage: 1,
  pageSize: 5,
  total: 0,
});

if (isLogged.value) {
  queryUserCanJoinActs();
} else {
  getAllList();
}

bus.on('onConnected', () => {
  queryUserCanJoinActs();
})

bus.on(bus.event.promoNotice, () => {
  getAllList();
});

function queryUserCanJoinActs() {
  mqant.request(topic.queryUserCanJoinActs, {}, function (data, topicName, msg) {
    log.info('queryUserCanJoinActs---', data.Data)
    if (data.Code == 0) {
      UserCanJoinActs = data.Data;
      getAllList();
    } else {
    }
  });
}


function getAllList() {
  let arr = [];
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key];
    if (
      info.type == curTag &&
      info.status &&
      info.activityId &&
      info.activityId.length > 0
    ) {
      let nowTime = Date.now();
      let startTime = Date.parse(info.openTime[0].replace(/-/g, '/'));
      let endTime = Date.parse(info.openTime[1].replace(/-/g, '/'));
      if (nowTime > startTime && nowTime < endTime) {
        if (isCanShow(info)) {
          arr.push(info);
        }
      }
    }
  }
  arr.sort((a, b) => {
    return Number(a.sort) - Number(b.sort);
  });

  recordObj.allList = arr;
  //记录总数
  paginationObj.total = arr.length;
  //分页截取
  sliceArr();
  log.info("recordObj.allList ", recordObj.allList);
  //拉取参加的活动
  if (isLogged.value) {
    getActiveJoinList();
  }
}

function isCanShow(info) {
  for (let key in info.activityId) {
    let id = info.activityId[key];
    if (isShowActive(id)) {
      return true;
    }
  }
  return false;
}

function isShowActive(id) {
  let curInfo = {};
  let activityConf = isLogged.value ? UserCanJoinActs : lobbyInfo.value.AllActivityConf
  for (let key in activityConf) {
    let info = activityConf[key];
    if (id == key) {
      curInfo = info;
      break;
    }
  }
  if (Object.keys(curInfo).length <= 0) return false;
  //条件判断
  if (curInfo.Status == 1 && curInfo.NowShow == false) {
    return true;
  }
  return false;
}

function getActiveJoinList() {
  mqant.request(topic.queryUserJoinedActs, {}, function (data, topicName, msg) {
    log.info("topic.queryUserJoinedActs", data);
    if (data.Code == 0) {
      JoinActs = data.Data.JoinActs;
      setActiveStatus();
    } else {
    }
  });
}
function setActiveStatus() {
  //总列表
  for (let key in recordObj.allList) {
    let info = recordObj.allList[key];
    info.btnStatus = getBtnStatus(info.activityId); //设置一个默认状态
  }
  //页列表
  for (let key in recordObj.list) {
    let info = recordObj.list[key];
    info.btnStatus = getBtnStatus(info.activityId); //设置一个默认状态
  }
}

function getBtnStatus(activityIdList) {
  for (let key in activityIdList) {
    let id = activityIdList[key];
    for (let key1 in JoinActs) {
      let info = JoinActs[key1];
      if (id == info.ActivityId && info.Status == "Doing") {
        return 1;
      }
    }
  }

  for (let key in activityIdList) {
    let id = activityIdList[key];
    if (isShowApply(id)) {
      return 0;
    }
  }

  return -1;
}

function isShowApply(id) {
  let curInfo = {};
  let activityConf = isLogged.value ? UserCanJoinActs : lobbyInfo.value.AllActivityConf
  for (let key in activityConf) {
    let info = activityConf[key];
    if (id == key) {
      curInfo = info;
      break;
    }
  }
  if (Object.keys(curInfo).length <= 0) return false;
  //条件判断
  if (!curInfo.ForceClose && curInfo.AllowApply) {
    return true;
  }
  return false;
}
function getVaildActiveList(item) {
  let arr = [];
  for (let key in item.activityId) {
    if (isShowApply(item.activityId[key])) {
      arr.push(item.activityId[key]);
    }
  }
  return arr;
}

function toPromoPop(item) {
  if (item.btnStatus == 0) {
    let vaildActiveList = getVaildActiveList(item);
    item.activityId = vaildActiveList;
    bus.emit(bus.event.promoApplyPop, { bool: true, data: item });
  } else if (item.btnStatus == 1) {
    let unLockActiveId = "";
    for (let key in item.activityId) {
      let id = item.activityId[key];
      for (let key1 in JoinActs) {
        let info = JoinActs[key1];
        if (id == info.ActivityId && info.Status == "Doing") {
          unLockActiveId = id;
          break;
        }
      }
    }
    bus.emit(bus.event.promoUnlockPop, {
      bool: true,
      data: { id: unLockActiveId, title: item.title },
    });
  }
}

function toInfo(item) {
  bus.emit(bus.event.promotionPop, { bool: true, data: item });
}

const handleSizeChange = (val) => {
  sliceArr();
};
const handleCurrentChange = (val) => {
  sliceArr();
};
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
  jumpToTop();
}

function jumpToTop() {
  bus.emit(bus.event.jumpToTop);
}
function getBannerImg(item) {
  if (tools.isMobile()) {
    return comApi.getNormalImg(tools.getCurLangValue(item.iconMobile));
  } else {
    return comApi.getNormalImg(tools.getCurLangValue(item.icon));
  }
}
</script>


<style lang="scss" scoped>
.promoApply {
  position: relative;

  .top-title {
    background-image: none;
    position: relative;
    margin-bottom: 15px;
    height: auto;
    text-align: center;

    h3 {
      background: none;
      width: 100%;
      height: auto;
      position: relative;
      border-bottom: none;
      padding: 0 30px;
      line-height: 30px;
      font-weight: 500;
      color: #fff;
      font-size: 24px;
    }
  }

  .memberform-fuild {
    border-radius: 10px;
    padding: 20px;
    background: #fff;

    .nopromo {
      margin-bottom: 20px;
      border-radius: 5px;
      background: #fff;
      color: #0f161f;
      border: 1px solid #fee5e9;
      padding: 100px 10px;
      text-align: center;
    }

    .apply-list {
      .apply-item {
        position: relative;
        margin-bottom: 20px;
        border-radius: 5px;
        background: #ffbf00;
        border: 1px solid #fee5e9;
        padding: 10px;

        .item-left {
          width: 100%;
          float: left;

          .promo-img {
            padding-bottom: 50%;
            width: 100%;
            height: 0;
            position: relative;
            text-align: center;
            overflow: hidden;

            img {
              margin: auto;
              width: 100%;
              height: 100%;
              left: 0;
              right: 0;
              position: absolute;
            }
          }
        }

        .item-right {
          width: 100%;

          .promoContent {
            padding: 10px 0 0;

            h3 {
              margin: 0 0 10px 0;
              padding: 0;
              color: #000;
              font-weight: 600;
              line-height: 30px;
              font-size: 24px;
            }

            .apply-btn {
              text-align: center;
              margin-top: 10px;

              input[type="button"] {
                cursor: pointer;
                background-image: linear-gradient(
                    4deg,
                    #665aff 17%,
                    #c267ff 100%
                  ),
                  linear-gradient(#c4c9f6, #c4c9f6);
                border: 0;
                border-radius: 3px;
                padding: 0 30px;
                height: 30px;
                color: #fff;
                font-size: 18px;
                line-height: 30px;
                margin: 5px;
              }

              .info {
                background-image: linear-gradient(
                    -2deg,
                    #b5b2ff 9%,
                    #ead2ff 100%
                  ),
                  linear-gradient(#2e39a9, #2e39a9) !important;
                color: #0f161f;
              }
            }
          }
        }

        .overflowContent {
          overflow-x: auto;
        }
      }
    }

    .pagination-wrapper {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: center;

      :deep(.el-pagination) {
        .is-active {
          background-color: #6c5f4e !important;
        }
      }
    }
  }
}
</style>