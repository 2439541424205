<template>
  <div class="header">
    <div class="header-flex navbar-expand-lg" :class="needSticky ? '-sticky' : ''">
      <div class="container-fluid -inner-container">
        <div class="d-lg-none">
          <div class="x-hamburger js-hamburger-toggle" @click="navObj.open = !navObj.open">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div v-if="navObj.open" class="sb-overlay" @click="closeLeftPop()"></div>
          <div class="sb-content" :class="navObj.open ? 'isOpen':''">
            <div class="content-container content-1 ">
              <template v-if="!langShow">
                <div class="sb-menu">
                  <div class="menu" @click="toRouter('/download')">
                    <div class="left">
                      <img src="@/assets/pc/header/svg-down.svg" alt="">
                      <p class="sb-name">{{t('下载')}}</p>
                    </div>
                    <div class="right">
                      <button class="icon-arrow">
                        <img src="@/assets/pc/common/back_left.svg" alt="">
                      </button>
                    </div>
                  </div>
                  <div class="menu" @click="langShow = !langShow">
                    <div class="left">
                      <img class="lang-img" :src="getCurLangIcon(locale)" alt="">
                      <p class="sb-name-lang">{{t('语言')}}</p>
                    </div>
                    <div class="right">
                      <button class="icon-arrow">
                        <img src="@/assets/pc/common/back_left.svg" alt="">
                      </button>
                    </div>
                  </div>
                </div>
                <div class="common-menu">
                  <div class="menu" @click="toRouter('/')">
                    <div class="icon-sb">
                      <img src="@/assets/pc/header/svg-home-page.svg" alt="">
                    </div>
                    <p class="sb-name">{{t('主页面')}}</p>
                  </div>
                  <div v-for="(item,index) in navObj.list" class="menu" @click="itemClick(item, index)">
                    <div class="icon-sb">
                      <img :src="comApi.getNormalImg(item.Icon)" alt="">
                    </div>
                    <p class="sb-name">{{tools.getCurLangValue(item.Title)}}</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="sb-header">
                  <div class="left">
                    <img src="@/assets/pc/header/langselect-btn.png" alt="">
                    <p class="title">{{t('区域 及 语言')}}</p>
                  </div>
                  <img class="beta-close-btn" src="@/assets/pc/common/dark-close.png" alt="" @click="closeLeftPop()">
                </div>
                <div class="LanguagesList_root_z8xst">
                  <div v-for="(item,index) in tools.getLanguageList()" class="LanguagesList_item_ITMix" :class="locale == item.shortName ? 'LanguagesList_itemActive_TC9Ls':'' " @click="langItemClick(item)">
                    <button class=" LanguagesList_itemButton_l1VGg">
                      <img class="lang-icon" :src="comApi.getNormalImg(item.icon)" alt="">
                      <span class="LanguagesList_itemInner_YiFFQ">
                        <span class="LanguagesList_itemTitle_A98V0">{{item.shortName}}</span>
                        <span class="LanguagesList_itemCaption_NuAGO">{{item.name}}</span>
                      </span>
                      <img v-if="locale == item.shortName" class="lang-select" src="@/assets/pc/common/svg-lang-select.svg" alt="">
                    </button>
                  </div>
                </div>
              </template>
            </div>
            <div class="standard-button-container">
              <button v-if="!isLogged" class="standard-submit-form-button btn-login" @click="toLoginPop()">{{t('登入')}}</button>
              <button v-else class="standard-submit-form-button" @click="toQuitlogin()">{{t('退出')}}</button>
            </div>
          </div>
        </div>
        <div class="headerBrand">
          <a class="navbar-brand">
            <img class="-logo" :src="comApi.getLogoImg()" alt="" @click="toRouter('/')">
          </a>
        </div>
        <div class="headerContent">
          <div v-if="isLogged" class="x-logged">
            <div class="-balance-container">
              <div class="-user-balance js-user-balance f-sm-6 f-7 ">
                <div class="-inner-box-wrapper" @click="toWalletPop()">
                  <span id="customer-balance" class="js-customer-balance">
                    <span class="text-green-lighter">{{ tools.formatNum2k(wallet.VndBalance +
                                        wallet.BonusBalance) }}</span>
                  </span>
                </div>
              </div>
              <div class="-profile-container">
                <div class="d-xl-none d-block">
                  <div class="js-ez-logged-sidebar">
                    <div class="x-profile-image" @click="isShowMember = !isShowMember">
                      <img class="img-fluid -profile-image" src="@/assets/pc/common/member_icon.svg" alt="">
                    </div>
                  </div>
                  <div class="x-menu-account-list-sidebar" :class="isShowMember ? '-open' : ''">
                    <div class="x-modal-account-menu-mobile">
                      <div class="-modal-profile-mobile d-xl-none d-block">
                        <div class="text-right">
                          <i class="fas fa-remove f-5" @click="isShowMember = false"></i>
                        </div>
                        <div class="x-profile-image">
                          <img class="img-fluid -profile-image" src="@/assets/pc/common/member_icon.svg" alt="">
                        </div>
                        <div class="-balance-container">
                          <div class="-text-username">
                            {{ userInfo.Account }}
                          </div>
                          <div class="-user-balance js-user-balance f-sm-6 f-7 ">
                            <div class="-inner-box-wrapper" @click="toWalletPop()">
                              <img class="img-fluid -ic-coin" src="@/assets/pc/common/ic-coin.png" alt="">
                              <span id="customer-balance" class="js-customer-balance">
                                <span class="text-green-lighter">{{
                                                                    tools.formatNum2k(wallet.VndBalance) }}</span>
                              </span>
                            </div>
                            <button type="button" class="-btn-balance" id="btn-customer-balance-reload">
                              <i class="fas fa-refresh f-9" :class="isRefresh2 ? 'fa-spin' : ''" @click="refresh2()"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <ul class="navbar-nav">
                        <li v-for="(item, index) in memberList" class="nav-item -btn-item -account-profile" @click="memberClick(item, index)">
                          <button type="button" class="nav-link js-close-account-sidebar -btn-item ">
                            <img class="img-fluid -icon-image" :src="item.img">
                            <span class="-text-menu">{{ t(item.lable) }}</span>
                            <span v-if="item.isNotice && mailInfo.mailUnreadNumAll > 0" class="badge">{{
                                                            mailInfo.mailUnreadNumAll }}</span>
                          </button>
                        </li>
                        <li class="nav-item logout-item" @click="toQuitlogin()">
                          <button type="button" class="nav-link js-close-account-sidebar -btn-item ">
                            <img class="img-fluid -icon-image" src="@/assets/pc/member/ic-menu-logout.png">
                            <span class="-text-menu">{{t('Log out')}}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="-overlay" @click="isShowMember = false"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="d-flex">
            <img class="down_class" src="@/assets/pc/header/svg_down.svg" alt="" @click="toRouter('/download')">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo, mailInfo } = useGetters("user", ["userInfo", "mailInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const { loginOut } = useMutations('user', ['loginOut'])
const { disConnect } = useActions('tcp', ['disConnect'])

const needSticky = ref(false);
const isRefresh1 = ref(false);
const isRefresh2 = ref(false);
const isShowNav = ref(false);
const isShowMember = ref(false);
const showDownPop = ref(getDownPopState())
const langShow = ref(false);



onMounted(() => {
  window.addEventListener("scroll", () => {
    let scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 50) {
      needSticky.value = true
    } else {
      needSticky.value = false
    }
  })
})

const navObj = reactive({
  active: -1,
  open: false,
  list: getNavList(),
})

const memberList = reactive([
  {
    lable: 'Profile',
    img: require('@/assets/pc/member/icon_profile.png'),
    router: '/member/profile/personal'
  },
  {
    lable: 'Cashier',
    img: require('@/assets/pc/member/icon_cashier.png'),
    router: '/member/cashier/deposit/depositInfo'
  },
  // {
  //   lable: 'Referrals',
  //   img: require('@/assets/pc/member/icon_profile.png'),
  //   router: '/member/referrals/referralRebateBonus'
  // },
  {
    lable: 'Vip',
    img: require('@/assets/pc/member/icon_vip.png'),
    router: '/member/vipRoot/vipInfo'
  },
  {
    lable: 'Promotion List',
    img: require('@/assets/pc/member/icon_promo.png'),
    router: '/member/promoList/promoApply'
  },
  {
    lable: 'Access application',
    isDwnload: true,
    img: require('@/assets/pc/member/icon_xz.png'),
    router: '/download',
  },
  {
    lable: 'Notifications',
    isNotice: true,
    img: require('@/assets/pc/member/icon_mail.png'),
    router: '/member/mailBox/mailBoxNew'
  }
])

const referralObj = {
  lable: 'Referrals',
  img: require('@/assets/pc/member/icon_profile.png'),
  router: '/member/referrals/referralRebateBonus'
}

getAllMemberAgentConf();
bus.on('onConnected', () => {
  getAllMemberAgentConf();
})

function getAllMemberAgentConf() {
  let parms = {
    ActivityId: 'AllMemberAsAgent'
  }
  mqant.request(topic.getAllMemberAgentConf, parms, function (data, topicName, msg) {
    log.info("topic.getAllMemberAgentConf", data);
    if (data.Code == 0) {
      let notShow = data.Data.conf.notShow;
      if (notShow !== 1) {
        memberList.splice(2, 0, referralObj)
      }
    }
  });

}

function getCurLangIcon(lang) {
  let allList = tools.getLanguageList();
  for (let key in allList) {
    if (allList[key].shortName == lang) {
      return comApi.getNormalImg(allList[key].icon)
    }
  }
}

function getLangImg(flag) {
  return require(`@/assets/common/lang/${flag}.png`)
}

function closeLeftPop() {
  navObj.open = false;
  langShow.value = false;
}

function getNavList() {
  let routerList = comApi.getRouterList();
  for (let key in routerList) {
    let info = routerList[key];
    if (info._id == "Slot") {
      info.routerData = { name: 'slot', parms: { flagId: 'gameCamp', hasChild: true, factoryName: '' } }
    } else if (info._id == "Lottery") {
      info.routerData = { name: 'slot', parms: { flagId: 'lottery' } }
    } else if (info._id == "Fish") {
      info.routerData = { name: 'slot', parms: { flagId: 'fish' } }
    } else if (info._id == "Card") {
      info.routerData = { name: 'slot', parms: { flagId: 'gameSkill' } }
    }
  }
  // log.info('routerHeaderList---', routerList)
  return routerList;
}

//处理选中路由 
function setSelectIndex() {
  let hasInRouter = false;
  if (curRouter.path == '/slot') {
    if (curRouter.query && curRouter.query.parms) {
      for (let key in navObj.list) {
        if (navObj.list[key].parms) {
          if (navObj.list[key].parms.flagId == JSON.parse(curRouter.query.parms).flagId) {
            navObj.active = Number(key);
            hasInRouter = true;
            break;
          }
        }
      }
    } else {
      for (let key in navObj.list) {
        let info = navObj.list[key];
        if (info.router && info.router == curRouter.path) {
          navObj.active = Number(key);
          hasInRouter = true;
          break
        }
      }
    }
  } else {
    for (let key in navObj.list) {
      let info = navObj.list[key];
      if (info.router && info.router == curRouter.path) {
        navObj.active = Number(key);
        hasInRouter = true;
        break
      }
    }
  }

  if (!hasInRouter) {
    navObj.active = -1;
  }
}
watch(() => {
  curRouter.path, setSelectIndex();
})

function langItemClick(item) {
  if (item.shortName == locale.value) return;
  locale.value = item.shortName;
  localStorage.setItem('localMyLanguage', item.shortName);
  navObj.open = false;
  langShow.value = false;

  tools.reload();
}

function quitLogin() {
  loginOut();
  disConnect();
  ElMessage.success(t('Exit successful'));
  router.push('/');
  isShowMember.value = false;
}

function refresh1() {
  isRefresh1.value = !isRefresh1.value;
  setTimeout(() => {
    isRefresh1.value = !isRefresh1.value;
  }, 2000);
}

function refresh2() {
  isRefresh2.value = !isRefresh2.value;
  setTimeout(() => {
    isRefresh2.value = !isRefresh2.value;
  }, 2000);
}

function itemClick(item, index) {
  navObj.active = index;
  if (item.router) {
    router.push({
      path: item.router,
    })
    navObj.open = false;
  }
}

function memberClick(item, index) {
  if (item.router) {
    if (item.isDwnload) {
      isShowMember.value = false;
      toIosDownPop();
      return;
    }
    router.push({
      path: item.router
    })
    isShowMember.value = false;
  }
}

function toQuitlogin() {
  bus.emit(bus.event.commonTipPop, {
    bool: true,
    data: {
      title: t('Want to log out?'),
      confirmTitle: t('Log out'),
      cannel: true,
      func: quitLogin
    }
  })
}


function toRouter(path) {
  router.push({
    path: path
  })
  navObj.open = false;
}
function toRegister() {
  bus.emit(bus.event.resgisterPop, true);
}

function toLoginPop() {
  navObj.open = false;
  bus.emit(bus.event.loginState, true);
}

function toWalletPop() {
  bus.emit(bus.event.walletPop, true);
}

function getDownPopState() {
  if (tools.platform() == 'h5_android') {
    return true;
  }
  if (tools.platform() == 'h5_ios' && !window.navigator.standalone) {
    return true;
  }
  return false;
}
function closeIosDown() {
  showDownPop.value = false;
  bus.emit(bus.event.iosDownPop, false);
}

function toIosDownPop() {
  if (tools.platform() == 'h5_android') {
    router.push('/download')
    return;
  }
  if (tools.platform() == 'h5_ios') {
    bus.emit(bus.event.iosDownPop, true);
    return;
  }
}

</script>

<style lang="scss" scoped>
.header {
  .header-flex {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 0.8rem;
    z-index: 100;
    transition: all 0.3s;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background: #2e1f40;
    .container-fluid {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      .-bg {
        -webkit-transform: translateX(-650px);
        transform: translateX(-650px);
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        max-width: 100%;
        height: 50px;
        opacity: 1;
        visibility: visible;
      }

      .fa-spin {
        transition: opacity 0.3s;
        animation: fa-spin 2s linear infinite;
      }

      @keyframes fa-spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
        }
      }

      .d-lg-none {
        display: flex;
        align-items: center;

        .x-hamburger {
          margin-right: 10px;
          margin-top: 7px;
          width: 25px;
          height: 24px;
          position: relative;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          transition: unset;
          cursor: pointer;

          span {
            width: 70%;
            display: block;
            position: absolute;
            height: 3px;
            background: #8697a2;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
          }

          span:first-child,
          span:nth-child(2) {
            transform-origin: left center;
          }

          span:first-child {
            top: 0;
            animation: x-hamburger-wave 0.4s ease-out infinite alternate;
          }

          span:nth-child(2) {
            top: 7px;
            animation: x-hamburger-wave 0.4s ease-out 0.3s infinite alternate;
          }

          span:nth-child(3) {
            top: 14px;
            transform-origin: left center;
            animation: x-hamburger-wave 0.4s ease-out 0.6s infinite alternate;
          }

          @keyframes x-hamburger-wave {
            0% {
              width: 70%;
            }

            100% {
              width: 100%;
            }
          }
        }
        .sb-overlay {
          position: fixed;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 45, 90, 0.4);
          z-index: 1;
        }
        .sb-content {
          position: fixed;
          top: 0;
          left: -310px;
          width: 310px;
          height: 100%;
          background: #2e1f40;
          color: #fff;
          z-index: 2;
          overflow-y: scroll;
          padding: 42px 0 100px;
          transition: 0.3s cubic-bezier(0.5, 0.03, 0.41, 1);
          .content-container {
            padding: 0 20px;
            .sb-menu {
              padding: 0 2px 60px 9px;
              text-transform: capitalize;
              .menu {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  img {
                    width: 40px;
                  }
                  .lang-img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                  }
                  .sb-name-lang {
                    margin: 0px 0 0 15px;
                    font-size: 13px;
                  }
                  .sb-name {
                    margin: 8px 0 0 15px;
                    font-size: 13px;
                  }
                }
                .right {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .icon-arrow {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #dbe9f0;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    margin: 0 0 0 0.35rem;
                    overflow: visible;
                    fill: #6da0dd;
                    border: none;
                    img {
                      width: 5px;
                      transform: rotate(180deg);
                    }
                  }
                }
              }
              .menu:not(:last-child) {
                margin: 0 0 25px;
              }
            }
            .common-menu {
              display: flex;
              flex-wrap: wrap;
              padding: 30px 0;
              border-top: 1px solid #ededed;
              .menu {
                padding: 20px 10px;
                width: calc(100% / 3);
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: center;
                .icon-sb {
                  width: 28px;
                  height: 28px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .sb-name {
                  margin: 12px 0 0;
                  text-align: center;
                  text-transform: capitalize;
                  word-break: keep-all;
                  white-space: nowrap;
                  font-size: 12px;
                }
              }
            }
            .sb-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #000;
              .left {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 50px;
                  margin: 0 13px 0 0;
                }
                p {
                  font-weight: 600;
                  font-size: 17px;
                }
              }
              .beta-close-btn {
                width: 12px;
                height: 12px;
                cursor: pointer;
              }
            }
            .LanguagesList_root_z8xst {
              color: #000;
              margin-top: 20px;
              border-top: 1px solid #ededed;
              display: flex;
              flex-direction: column;
              border-radius: 10px;
              overflow: hidden;
              .LanguagesList_item_ITMix {
                background-color: #20283c;
                transition: background-color 0.2s ease-in-out;
              }
              .LanguagesList_item_ITMix:not(:last-child) {
                margin-bottom: 1px;
              }
              .LanguagesList_itemActive_TC9Ls {
                pointer-events: none;
              }
              .LanguagesList_itemButton_l1VGg {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 9px 17px;
                border: none;
                .lang-icon {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  object-fit: cover;
                }
                .LanguagesList_itemInner_YiFFQ {
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  margin: 0 12px;
                  .LanguagesList_itemTitle_A98V0 {
                    margin-bottom: 3px;
                    font-size: 13px;
                    line-height: 16px;
                    font-weight: 600;
                  }
                  .LanguagesList_itemCaption_NuAGO {
                    color: #77829b;
                    font-size: 11px;
                    line-height: 13px;
                    text-transform: capitalize;
                  }
                }
                .lang-select {
                  width: 15px;
                  height: 15px;
                  display: block;
                }
              }
            }
          }
          .standard-button-container {
            margin: 60px 0 0;
            padding: 0 25px 0 24px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
              font-weight: 600;
              width: 100%;
              height: 43px;
              background: #ffbf00;
              color: #fff;
              border-radius: 5px;
              font-size: 17px;
              border: none;
            }
            .btn-login {
              background: #ffbf00;
              color: #fff;
            }
          }
        }
        .isOpen {
          left: 0;
        }
      }
      .headerBrand {
        position: relative;

        .navbar-brand {
          padding-top: 0.3125rem;
          padding-bottom: 0.3125rem;
          margin-right: 0.75rem;
          font-size: 1.25rem;
          line-height: inherit;
          white-space: nowrap;
          position: relative;
          .-logo {
            max-height: 45px;
            max-width: 120px;
          }
        }
      }
      .x-menu-list-sidebar {
        visibility: hidden;
        position: fixed;
        left: -100%;
        top: 0;
        height: 100%;
        min-width: 110px;
        width: 150px;
        text-align: center;
        transition: all 0.3s;
        overflow: hidden;
        border-radius: 0 30px 30px 0;

        .-inner-wrapper {
          position: relative;
          height: 100%;
          padding: 0.5rem 1.25rem 90px;
          background: #2e1f40;
          z-index: 60;
          overflow: auto;

          .-brand-heading {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem 0;

            a {
              position: relative;
              text-decoration: none;
              background-color: transparent;

              img {
                width: 60px;
                max-width: 100%;
                height: auto;
              }

              .navbar-nav {
                margin-top: 1rem;
                flex-direction: column;
                display: flex;
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;

                .nav-item {
                  a {
                    padding: 0.4rem 0;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                      width: 50px;
                    }

                    .-text {
                      margin-top: 6px;
                      font-size: 0.875rem;
                      color: #333;
                      transition: color 0.3s;
                    }
                  }
                }
              }

              .-logo {
                width: 60px;
                max-width: 100%;
                height: auto;
              }
            }
          }

          .navbar-nav {
            margin-top: 1rem;
            flex-direction: column;
            display: flex;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;

            .nav-item {
              .nav-link {
                padding: 0.4rem 0;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                .-img-menu {
                  width: 50px;
                }

                .-text {
                  margin-top: 6px;
                  font-size: 0.875rem;
                  color: #333;
                  transition: color 0.3s;
                }
              }

              .active {
                position: relative;
              }
            }
          }
        }

        .-overlay {
          display: none;
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 55;
          background: rgba(0, 0, 0, 0.35);
        }
      }

      .x-menu-list-open {
        left: 0;
        visibility: visible;

        .-overlay {
          display: block;
        }
      }

      .headerContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .btn {
          font-weight: 400;
          text-align: center;
          vertical-align: middle;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-color: transparent;
          font-size: 1rem;
          line-height: 1.5;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          border-radius: 25px;
          color: #fff;
          border: none;
          padding: 0.5rem 1.5rem;
        }

        .d-flex {
          display: flex !important;
          gap: 10px;
          .-btn-com {
            padding: 0.35rem 0.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fafafa;
            background: linear-gradient(#f1cbff, #d977fd);
            border: none;
            position: relative;
            transition: all 0.2s;
            .-text {
              font-size: 0.75rem;
            }
          }
          .-btn-play-demo {
            background: linear-gradient(#f1cbff, #d977fd);
          }
          .-btn-header-login {
            background: linear-gradient(180deg, #febcbd, #ff888a);
          }
          .down_class {
            width: 32px;
            height: 32px;
          }
        }

        .x-logged {
          display: flex;

          .-balance-container {
            margin: auto 0;
            display: flex;
            gap: 5px;
            .-user-balance {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.9rem !important;

              .-inner-box-wrapper {
                position: relative;
                margin-right: 5px;
                padding: 4px 10px 4px 4px;
                font-weight: 500;
                border-radius: 20px;
                color: #333;
                background: #fff;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                cursor: pointer;

                .-ic-coin {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  width: 30px;
                  transform: translateY(-50%);
                }

                .text-green-lighter {
                  font-weight: 500;
                }
              }

              .-btn-balance {
                display: flex;
                position: relative;
                top: -1px;
                padding: 0;
                color: #fff;
                background: transparent;
                border: none;

                i {
                  cursor: pointer;
                  color: #333;
                }
              }
            }
          }

          .-deposit-container {
            margin: auto 0.5rem;
          }

          .-withdraw-container {
            margin: auto 0.5rem;
          }

          .-profile-container {
            position: relative;

            .x-profile-image {
              .-profile-image {
                width: 20px;
              }
            }

            .d-xl-none {
              .x-menu-account-list-sidebar {
                visibility: hidden;
                position: fixed;
                right: -100%;
                top: 0;
                height: 100%;
                min-width: 100px;
                z-index: 60;
                text-align: center;
                transition: all 0.3s;

                .x-modal-account-menu-mobile {
                  position: relative;
                  height: 100%;
                  padding: 1rem 1.25rem;
                  background: #2e1f40;
                  z-index: 60;
                  overflow: auto;
                  border-radius: 30px 0 0 30px;
                  margin-right: 0;
                  --max-width: 260px;
                  min-width: var(--max-width);

                  .-modal-profile-mobile {
                    .text-right {
                      text-align: right !important;

                      .fas {
                        color: #bcb8c9;
                      }
                    }

                    .x-profile-image {
                      img {
                        width: 40px;
                      }
                    }

                    .-balance-container {
                      flex-direction: column;
                      color: #472d59;
                      padding: 0.75rem 0;
                      border-bottom: 1px solid #e7dcef;
                      margin: auto 0;
                      display: flex;

                      .-text-username {
                        display: block;
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: #fff;
                      }

                      .-user-balance {
                        margin-top: 5px;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        font-size: 0.9rem !important;

                        .-inner-box-wrapper {
                          position: relative;
                          margin-right: 5px;
                          font-weight: 500;
                          border-radius: 20px;
                          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                          color: #333;
                          background: #fff;
                          padding: 4px 10px 4px 35px;

                          img {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                            width: 30px;
                          }

                          .js-customer-balance {
                            font-weight: 500;
                            padding: 0 7px;

                            .text-green-lighter {
                              font-weight: 500;
                            }
                          }
                        }

                        .-btn-balance {
                          display: flex;
                          position: relative;
                          top: -1px;
                          padding: 0;
                          color: #fff;
                          background: transparent;
                          border: none;

                          .fas {
                            color: #e5a0ff !important;
                            margin: auto 0;
                          }
                        }
                      }
                    }
                  }

                  .navbar-nav {
                    display: flex;
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                    margin-top: 1rem;
                    flex-direction: column;
                    overflow: auto;

                    .nav-item {
                      box-shadow: 0 2px 20px transparent;
                      margin-bottom: 0.5rem;
                      border-radius: 10px;

                      .nav-link {
                        cursor: pointer;
                        border-radius: 10px;
                        font-weight: 500;
                        width: 100%;
                        background: #2e1f40;
                        border: none;
                        text-align: center;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0.5rem 0.75rem;

                        img {
                          width: 40px;
                        }

                        .-text-menu {
                          color: #fff;
                          margin: auto 0 auto 0.75rem;
                          font-weight: 500;
                        }

                        .badge {
                          display: inline-block;
                          background: #ffc107;
                          border-radius: 10px;
                          margin-left: 10px;
                          padding: 3px 5px;
                          min-width: 20px;
                          color: #000;
                          font-size: 12px;
                          font-weight: 700;
                          line-height: 1;
                          text-align: center;
                          white-space: nowrap;
                          vertical-align: middle;
                        }
                      }
                    }

                    .lang-item {
                      :deep(.el-collapse) {
                        border: none;
                        --el-collapse-header-height: auto;
                        --el-collapse-header-bg-color: #ffe9e8;

                        .el-collapse-item {
                          margin-bottom: 0px;
                        }

                        .el-collapse-item__header {
                          cursor: pointer;
                          border-radius: 10px;
                          font-weight: 500;
                          width: 100%;
                          height: auto;
                          background-color: #ffe9e8;
                          border: none;
                          text-align: center;
                          display: flex;
                          align-items: center;
                          flex-direction: row;
                          align-items: center;
                          justify-content: flex-start;
                          padding: 0.5rem 0.75rem;

                          .icon {
                            width: 40px;
                            height: 40px;
                            position: relative;

                            img {
                              width: 100%;
                              position: absolute;
                              left: 50%;
                              top: 50%;
                              transform: translate(-50%, -50%);
                            }
                          }

                          span {
                            color: #0f161f;
                            margin: auto 0 auto 0.75rem;
                            font-weight: 500;
                            font-size: 1rem;
                          }

                          .el-icon {
                            font-size: 16px;
                            font-weight: 500;
                          }
                        }

                        .el-collapse-item__wrap {
                          background: #dbd8f9;
                          border: none;
                        }

                        .el-collapse-item__content {
                          padding-bottom: 0;
                          font-size: 13px;
                          color: #303133;

                          .panel-body {
                            position: relative;

                            .swiper-position {
                              padding: 5px;
                              position: relative;
                              min-height: 40px;
                              overflow-x: auto;
                              overflow-y: hidden;
                              display: flex;
                              max-width: calc(var(--max-width) - 2 * 1.25rem);

                              .swiper {
                                .swiper-slide {
                                  width: 25% !important;

                                  .lang-icon {
                                    display: inline-block;
                                    border: 1px solid #efeef6;
                                    padding-top: 10px;
                                    text-align: center;

                                    a {
                                      padding: 0px;
                                      color: #0f161f;

                                      img {
                                        display: inline-block;
                                        width: 55%;
                                      }
                                    }
                                  }

                                  .active {
                                    background: #f3f3ff;

                                    a {
                                      color: #333333;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .-overlay {
                  display: none;
                  width: 100%;
                  height: 100%;
                  position: fixed;
                  top: 0;
                  left: 0;
                  z-index: 55;
                  background: rgba(0, 0, 0, 0.35);
                }
              }

              .-open {
                visibility: visible;
                right: 0;

                .-overlay {
                  display: block;
                }
              }
            }
          }
        }
      }
      .lang-link {
        width: auto;
        position: relative;
        color: #fff;
        float: right;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;

        .lang-arrow {
          display: flex;
          align-items: center;

          .lang-img {
            display: inline-block;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            position: relative;
            overflow: hidden;

            img {
              margin: auto;
              width: auto;
              height: 100%;
              position: absolute;
              left: -100%;
              right: -100%;
            }
          }

          i {
            margin-left: 5px;
            vertical-align: top;
            line-height: 25px;
            color: #333;
          }
        }

        .dropdown-lang {
          // display: none;
          border-radius: 3px;
          background: #f3f3ff;
          border: 1px solid #595959;
          right: 8px;
          position: absolute;
          z-index: 100;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;

          ul {
            li {
              text-align: center;

              a {
                display: block;
                padding: 10px;
                text-decoration: none;
                -webkit-transition: all 0.2s;
                transition: all 0.2s;
                cursor: pointer;

                .lang-img {
                  display: inline-block;
                  border-radius: 50%;
                  width: 25px;
                  height: 25px;
                  position: relative;
                  overflow: hidden;

                  img {
                    height: 25px;
                    margin: auto;
                    width: auto;
                    position: absolute;
                    left: -100%;
                    right: -100%;
                  }
                }
              }

              a:hover {
                background: #595959;
              }
            }
          }
        }
      }
    }
  }
  .-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, #fff2f1, #ffe3e1);
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12),
      0 1px 2px hsla(0, 7.3%, 92%, 0.24);

    .-profile-name {
      color: #333;
    }

    .headerContent {
      .-balance-container {
        .fa-refresh {
          color: #333;
        }
      }
    }
  }
  .header-download {
    position: relative;
    z-index: 100;
    background: #7f7fd6;
    border-bottom: 1px solid hsla(0, 0%, 66.7%, 0.5);
    .close-icon {
      position: relative;
      width: 20px;
      height: 20px;
      margin: 20px 15px 0;
      display: inline-block;
      border-width: 0;
      z-index: 1;
      padding: 0;
      background: #7f7fd6;

      span {
        display: block;
        background: #fff;
        border-radius: 10px;
        width: 100%;
        height: 2px;
        left: 0;
        position: absolute;
      }

      span:first-child {
        top: 6px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      span:nth-child(2) {
        top: 6px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }

    .dw-btn {
      display: inline-block;
      width: 100%;
      padding-left: 55px;
      margin: 7px 0 7px -55px;
      color: #fff;
    }

    .dw-logo {
      width: 60px;
      margin-right: 10px;
      vertical-align: middle;
    }

    .open-btn {
      position: relative;
      margin: 3px 10px;
      padding: 7px 20px;
      border-radius: 25px;
      background-color: #ddd;
      color: #000;
      float: right;
      font-size: 12px;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: heartBeat;
      animation-name: heartBeat;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }

    @keyframes heartBeat {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      14% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }

      28% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      42% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }

      70% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}
</style>