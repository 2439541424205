<template>
  <div class="promotionPop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="promoteclose-wrapper">
        <button type="button" class="outside-close" @click="close()"></button>
      </div>
      <div class="regulation-bg">
        <div class="regulation-inner">
          <div>
            <div class="promotionDialog">
              <div>
                <div class="promo-img">
                  <img :src="getBannerImg(props.data)" lazy="loaded">
                </div>
                <div v-if="curObj.btnStatus == 0" class="submitbtn-wrap promotion-submitbtn-wrap">
                  <button data-analytics="promotionId-" @click="toPromoPop(props.data)">{{t('Apply')}}</button>
                </div>
                <div v-if="curObj.btnStatus == 1" class="submitbtn-wrap promotion-submitbtn-wrap">
                  <button data-analytics="promotionId-" @click="toPromoPop(props.data)">{{t('Unlock')}}</button>
                </div>
                <h2 class="promo-title">{{ tools.getCurLangValue(props.data.title) }}</h2>
                <div class="min-height" v-html="tools.getCurLangValue(props.data.desc)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {
    title: '',
    desc: '',
  }
})

const curObj = reactive({
  btnStatus: -1,// -1:不显示0//Apply，1://Unlock
  unLockActiveId: '',
  list: [],//正在进行的活动
})

bus.on(bus.event.promoNotice, () => {
  getActiveJoinList();
})

//设置活动状态
if (!isLogged.value || !props.data.activityId || props.data.activityId.length <= 0) {
  curObj.btnStatus = -1;
} else {
  getActiveJoinList();
}

function getActiveJoinList() {
  mqant.request(topic.queryUserJoinedActs, {}, function (data, topicName, msg) {
    log.info('topic.queryUserJoinedActs', data)
    if (data.Code == 0) {
      curObj.list = data.Data.JoinActs;
      setBtnStatus();
    } else {
    }
  })
}

function setBtnStatus() {
  for (let key in props.data.activityId) {
    let id = props.data.activityId[key];
    for (let key1 in curObj.list) {
      let info = curObj.list[key1];
      if (id == info.ActivityId && info.Status == 'Doing') {
        curObj.btnStatus = 1;
        curObj.unLockActiveId = id;
        return;
      }
    }
  }

  for (let key in props.data.activityId) {
    let id = props.data.activityId[key];
    if (isShowApply(id)) {
      curObj.btnStatus = 0;
      return;
    }
  }
}

function isShowApply(id) {
  let curInfo = {}
  for (let key in lobbyInfo.value.AllActivityConf) {
    let info = lobbyInfo.value.AllActivityConf[key];
    if (id == key) {
      curInfo = info;
      break;
    }
  }
  if (Object.keys(curInfo).length <= 0) return false;
  //条件判断
  if (!curInfo.ForceClose && curInfo.AllowApply && curInfo.Status == 1 && curInfo.NowShow == false) {
    return true
  }
  return false;
}

function getVaildActiveList(item) {
  let arr = [];
  for (let key in item.activityId) {
    if (isShowApply(item.activityId[key])) {
      arr.push(item.activityId[key])
    }
  }
  return arr;
}

function getBannerImg(item) {
  if (tools.isMobile()) {
    return comApi.getNormalImg(tools.getCurLangValue(item.iconMobile));
  } else {
    return comApi.getNormalImg(tools.getCurLangValue(item.icon));
  }
}

function toPromoPop(item) {
  if (curObj.btnStatus == 0) {
    let vaildActiveList = getVaildActiveList(item);
    item.activityId = vaildActiveList;
    bus.emit(bus.event.promoApplyPop, { bool: true, data: item })
  } else if (curObj.btnStatus == 1) {
    bus.emit(bus.event.promoUnlockPop, { bool: true, data: { id: curObj.unLockActiveId, title: item.title } })
  }
}



function close() {
  bus.emit(bus.event.promotionPop, { bool: false, data: {} });
}
</script>

<style lang="scss" scoped>
.promotionPop {
  position: relative;

  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    border-radius: 2px;
    position: relative;
    margin: 0 auto 50px;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #606266;
      font-size: 14px;
      overflow: visible;
      padding: 0;

      .promoteclose-wrapper {
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 9999;

        .outside-close {
          position: absolute;
          z-index: 10;
          border: 0;
          text-align: center;
          cursor: pointer;
          width: 30px;
          height: 30px;
          -webkit-appearance: none;

          top: 0;
          right: -70px;
          padding: 30px;
          background: #999999 url("@/assets/pc/pop/light-close.png") no-repeat
            50%;
          opacity: 1;
        }
      }

      .regulation-bg {
        border-radius: 10px;
        padding: 10px;
        background-image: linear-gradient(-2deg, #f3f5f6 0%, #ffff 100%),
          linear-gradient(#ffffff, #ffffff);

        .regulation-inner {
          border-radius: 10px;
          padding: 10px;
          word-break: break-word;

          .promo-img {
            position: relative;
            width: 100%;
            padding-bottom: 27.6%;
            height: 0;
            overflow: hidden;

            img {
              width: 100%;
              border-radius: 10px;
              height: 100%;
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
            }
          }

          .submitbtn-wrap {
            padding: 20px 0 10px;
            position: sticky;
            margin: auto;
            overflow: auto;
            top: 0;
            display: block;
            left: 0;
            right: 0;
            width: auto;
            z-index: 50;
            -webkit-transition: background-color 0.5s ease;
            transition: background-color 0.5s ease;
            -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
            text-align: center;
            animation-duration: 1.5s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: both;
            animation-name: heartBeat;
            animation-iteration-count: infinite;

            button {
              background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(#ffbf00),
                to(#fe725d)
              );
              background-image: linear-gradient(180deg, #ffbf00 0, #fe725d);
              background-repeat: repeat-x;
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FF1177AA", endColorstr="#FFfe725d", GradientType=0);
              -webkit-box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
              box-shadow: inset 0 4px 0 0 hsla(0, 0%, 100%, 0.3);
              -webkit-transition: all 0.5s ease;
              transition: all 0.5s ease;
              cursor: pointer;
              display: inline-block;
              width: auto;
              height: auto;
              padding: 10px 40px;
              font-size: 18px;
              color: #fff;
              line-height: 30px;
              letter-spacing: 1px;
              text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
              border-radius: 50px;
              border: 2px solid #fe725d;
            }

            button:hover {
              background-image: linear-gradient(180deg, #ffbf00 0, #ffbf00);
              background-repeat: repeat-x;
            }

            @keyframes heartBeat {
              0% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }

              14% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              28% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }

              42% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              70% {
                -webkit-transform: scale(1);
                transform: scale(1);
              }
            }
          }

          h2 {
            font-size: 30px;
          }

          .promo-title {
            margin: 0;
            padding: 20px 0;
            color: #000;
            font-weight: 700;
          }

          .min-height {
            min-height: 100px;
            span {
              max-width: 100%;
            }
            img {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .promotionPop {
    :deep(.el-dialog) {
      margin: 10px auto 50px !important;
      width: 100% !important;

      .el-dialog__body {
        .promoteclose-wrapper {
          .outside-close {
            top: 10px;
            right: 10px;
            padding: 20px;
            background-size: 20px 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .promo-img {
    padding-bottom: 50% !important;
  }
}
</style>