<template>
  <div class="deposit">
    <!-- <div class="member-section">
      <div class="container">
        <div class="container-wrapper">
          <div class="member-content">
            <div class="tab-content">
              <div class="memberform-wrap">
                <div class="monet_save">
                  <ul class="member-money-btn">
                    <li v-for="(item, index) in obj.list" :class="obj.active == index ? 'active' : ''" @click="itemClick(item, index)">
                      <a>{{ t(item.label) }}</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <router-view />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <router-view />
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import MemberTitle from "@/views/pc/components/member/MemberTitle.vue"
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const obj = reactive({
  active: 0,
  list: [
    {
      label: 'Deposit',
      router: '/member/cashier/deposit/depositInfo'
    },
    {
      label: 'Deposit History',
      router: '/member/cashier/deposit/depositRecord'
    }
  ]
})

//处理选中路由 
function setSelectIndex() {
  if (curRouter.path.includes('deposit')) {
    //选择
    for (let key in obj.list) {
      let info = obj.list[key];
      if (info.router == curRouter.path) {
        obj.active = Number(key);
      }
    }
  }
}
watch(() => {
  curRouter.path, setSelectIndex();
})

function itemClick(item, index) {
  if (item.router) {
    router.push({
      path: item.router
    })
  }
}

function back() {
  router.push(
    {
      path: '/member/memberHall'
    }
  );
}
</script>

<style lang="scss" scoped>
.deposit {
  position: relative;
  transform: scale(0.9);
  transform-origin: top;
  .member-section {
    padding-top: 0;
    padding-bottom: 15px;

    .container {
      width: 100%;

      .container-wrapper {
        .member-content {
          //   padding-top: 15px;

          .tab-content {
            .top-title {
              background-image: none;
              position: relative;
              margin-bottom: 15px;
              height: auto;
              text-align: center;

              h3 {
                background: none;
                width: 100%;
                height: auto;
                position: relative;
                border-bottom: none;
                padding: 0 30px;
                line-height: 30px;
                font-weight: 500;
                color: #fff;
                font-size: 24px;
              }

              .back-hall {
                width: 30px;
                height: 30px;
                display: block;
                background: url("@/assets/pc/common/back-hall.png");
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                position: absolute;
                z-index: 3;
                line-height: 30px;
                color: transparent;
              }
            }

            .memberform-wrap {
              border-radius: 10px;
              padding: 0 10px;
              background: #fff;

              .monet_save {
                .member-money-btn {
                  padding-top: 10px;
                  margin-bottom: 10px;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -ms-flex-pack: distribute;
                  justify-content: space-around;
                  text-align: center;

                  li {
                    border-radius: 5px;
                    background: #f7f7f7;
                    width: 45%;
                    text-align: center;

                    a {
                      cursor: pointer;
                      display: block;
                      padding: 10px 2px;
                      color: #8697a2;
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 1.3;
                      text-decoration: none;
                      white-space: pre-wrap;
                    }
                  }

                  .active {
                    background: linear-gradient(270deg, #ffbf00 4%, #ffbf00);

                    a {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>