import { theme } from "@/views/theme";

let rootView = require("@/views/mobile/RootView.vue").default;
let home = require("@/views/mobile/components/Home.vue").default;
let slot = require("@/views/mobile/components/Slot.vue").default;
let fish = require("@/views/mobile/components/Fish.vue").default;
let special = require("@/views/mobile/components/Special.vue").default;
let chess = require("@/views/mobile/components/Chess.vue").default;
let cockFight = require("@/views/mobile/components/CockFight.vue").default;
let lottery = require("@/views/mobile/components/Lottery.vue").default;
let casino = require("@/views/mobile/components/Casino.vue").default;
let sport = require("@/views/mobile/components/Sport.vue").default;
let promotion = require("@/views/mobile/components/Promotion.vue").default;

let download = require("@/views/mobile/components/Download.vue").default;
let vip = require("@/views/mobile/components/Vip.vue").default;
let vipDetail = require("@/views/mobile/components/VipDetail.vue").default;
let member = require("@/views/mobile/components/Member.vue").default;
let cashier = require("@/views/mobile/components/member/Cashier.vue").default;

let deposit = require("@/views/mobile/components/member/Deposit.vue").default;
let depositInfo =
  require("@/views/mobile/components/member/DepositInfo.vue").default;
let depositRecord =
  require("@/views/mobile/components/member/DepositRecord.vue").default;
let withdrawal =
  require("@/views/mobile/components/member/Withdrawal.vue").default;
let withdrawalInfo =
  require("@/views/mobile/components/member/WithdrawalInfo.vue").default;
let withdrawalRecord =
  require("@/views/mobile/components/member/WithdrawalRecord.vue").default;
let profile = require("@/views/mobile/components/member/Profile.vue").default;
let personal = require("@/views/mobile/components/member/Personal.vue").default;
let betHistory =
  require("@/views/mobile/components/member/BetHistory.vue").default;
let password = require("@/views/mobile/components/member/Password.vue").default;
let passwordLogin =
  require("@/views/mobile/components/member/PasswordLogin.vue").default;
let vipRoot = require("@/views/mobile/components/member/VipRoot.vue").default;
let vipInfo = require("@/views/mobile/components/member/VipInfo.vue").default;
let vipWelfare =
  require("@/views/mobile/components/member/VipWelfare.vue").default;
let vipHistory =
  require("@/views/mobile/components/member/VipHistory.vue").default;

let mailBox = require("@/views/mobile/components/member/MailBox.vue").default;
let mailBoxNew =
  require("@/views/mobile/components/member/MailBoxNew.vue").default;
let mailBoxBonus =
  require("@/views/mobile/components/member/MailBoxBonus.vue").default;
let mailBoxSystem =
  require("@/views/mobile/components/member/MailBoxSystem.vue").default;
let mailBoxEvent =
  require("@/views/mobile/components/member/MailBoxEvent.vue").default;
let mailBoxPersonal =
  require("@/views/mobile/components/member/MailBoxPersonal.vue").default;
let mailBoxAccount =
  require("@/views/mobile/components/member/MailBoxAccount.vue").default;
let promoList =
  require("@/views/mobile/components/member/PromoList.vue").default;
let promoApply =
  require("@/views/mobile/components/member/PromoApply.vue").default;
let promoInfoList =
  require("@/views/mobile/components/member/PromoInfoList.vue").default;
let promoHistory =
  require("@/views/mobile/components/member/PromoHistory.vue").default;
let referrals =
  require("@/views/mobile/components/member/Referrals.vue").default;
let referralRebateBonus =
  require("@/views/mobile/components/member/ReferralRebateBonus.vue").default;

let memberChilren = [
  {
    path: "cashier",
    name: "cashier",
    component: cashier,
    children: [
      {
        path: "deposit",
        name: "deposit",
        component: deposit,
        children: [
          {
            path: "depositInfo",
            name: "depositInfo",
            component: depositInfo,
          },
          {
            path: "depositRecord",
            name: "depositRecord",
            component: depositRecord,
          },
        ],
      },
      {
        path: "withdrawal",
        name: "withdrawal",
        component: withdrawal,
        children: [
          {
            path: "withdrawalInfo",
            name: "withdrawalInfo",
            component: withdrawalInfo,
          },
          {
            path: "withdrawalRecord",
            name: "withdrawalRecord",
            component: withdrawalRecord,
          },
        ],
      },
    ],
  },
  {
    path: "profile",
    name: "profile",
    component: profile,
    children: [
      {
        path: "personal",
        name: "personal",
        component: personal,
      },
      {
        path: "betHistory",
        name: "betHistory",
        component: betHistory,
      },
      {
        path: "password",
        name: "password",
        component: password,
        children: [
          {
            path: "passwordLogin",
            name: "passwordLogin",
            component: passwordLogin,
          },
        ],
      },
    ],
  },
  {
    path: "vipRoot",
    name: "vipRoot",
    component: vipRoot,
    children: [
      {
        path: "vipInfo",
        name: "vipInfo",
        component: vipInfo,
      },
      {
        path: "vipWelfare",
        name: "vipWelfare",
        component: vipWelfare,
      },
      {
        path: "vipHistory",
        name: "vipHistory",
        component: vipHistory,
      },
    ],
  },
  {
    path: "mailBox",
    name: "mailBox",
    component: mailBox,
    children: [
      {
        path: "mailBoxNew",
        name: "mailBoxNew",
        component: mailBoxNew,
      },
      {
        path: "mailBoxBonus",
        name: "mailBoxBonus",
        component: mailBoxBonus,
      },
      {
        path: "mailBoxSystem",
        name: "mailBoxSystem",
        component: mailBoxSystem,
      },
      {
        path: "mailBoxEvent",
        name: "mailBoxEvent",
        component: mailBoxEvent,
      },
      {
        path: "mailBoxAccount",
        name: "mailBoxAccount",
        component: mailBoxAccount,
      },
      {
        path: "mailBoxPersonal",
        name: "mailBoxPersonal",
        component: mailBoxPersonal,
      },
    ],
  },
  {
    path: "promoList",
    name: "promoList",
    component: promoList,
    children: [
      {
        path: "promoApply",
        name: "promoApply",
        component: promoApply,
      },
      {
        path: "promoInfoList",
        name: "promoInfoList",
        component: promoInfoList,
      },
      {
        path: "promoHistory",
        name: "promoHistory",
        component: promoHistory,
      },
    ],
  },
  {
    path: "referrals",
    name: "referrals",
    component: referrals,
    children: [
      {
        path: "referralRebateBonus",
        name: "referralRebateBonus",
        component: referralRebateBonus,
      },
    ],
  },
];

let rootChilren = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/sport",
    name: "sport",
    component: sport,
  },
  {
    path: "/promotion",
    name: "promotion",
    component: promotion,
  },
  {
    path: "/vip",
    name: "vip",
    component: vip,
  },
  {
    path: "/vipDetail",
    name: "vipDetail",
    component: vipDetail,
  },
  {
    path: "/member",
    name: "member",
    component: member,
    children: memberChilren,
  },
  {
    path: "/download",
    name: "download",
    component: download,
  },
];
const routes = [
  {
    path: "/",
    name: "rootView",
    component: rootView,
    children: rootChilren,
  },
  {
    path: "/casino",
    name: "casino",
    component: casino,
  },
  {
    path: "/slot",
    name: "slot",
    component: slot,
  },
  {
    path: "/fish",
    name: "fish",
    component: fish,
  },
  {
    path: "/special",
    name: "special",
    component: special,
  },
  {
    path: "/chess",
    name: "chess",
    component: chess,
  },
  {
    path: "/cockFight",
    name: "cockFight",
    component: cockFight,
  },
  {
    path: "/lottery",
    name: "lottery",
    component: lottery,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

export { routes };
